<template lang='pug'>
.complain-page
    template(v-if="!isOk")
        .title 投诉
        .form-box
            .item
                .label 
                    span.color *
                    span 1.投诉该群组的原因：
                .value
                    van-dropdown-menu(active-color="#05C2CB")
                        van-dropdown-item(v-model="value1" :options="option1")
            .item
                .label 
                    span.color *
                    span 2.投诉内容详情：
                .value
                    van-cell-group
                        van-field(
                            v-model="value2" 
                            rows="2" 
                            autosize 
                            type="textarea" 
                            maxlength="200" 
                            show-word-limit
                            placeholder="请输入投诉内容"
                        )
            //- .item
            //-     .label 
            //-         span.color *
            //-         span 3.相关证据上传（图片/ 视频）：
            //-     .value
            //-         van-uploader(
            //-             accept="image/*, video/*"
            //-             v-model="fileList" 
            //-             :after-read="afterRead"
            //-         )
        .submit
            van-button(style="width: 100%" type="primary" color="#05C2CB" :loading="loading" @click="submit") 提交
    template(v-else)
        .result 
            span 投诉成功
            van-icon(name="passed" color="#05C2CB" size="2rem")
</template>

<script>
import axios from 'axios';
export default {
    data () {
        return {
            isOk: false,
            value1: '该群组存在诈骗行为',
            value2: '',
            option1: [
                {
                    text: '该群组存在诈骗行为',
                    value: '该群组存在诈骗行为'
                },
                {
                    text: '该群组传播谣言信息',
                    value: '该群组传播谣言信息'
                },
                {
                    text: '该群组在发布仿冒品信息',
                    value: '该群组在发布仿冒品信息'
                },
                {
                    text: '其他',
                    value: '其他'
                }
            ],
            loading: false,
            fileList: []
        };
    },
    computed: {
        uid () {
            return this.$route.query.uid;
        }
    },
    methods: {
        submit () {
            if (!this.uid) {
                this.$toast('uid缺失');
                return;
            }
            if (!this.value2) {
                this.$toast('请填写投诉内容');
                return;
            }
            // let arr = this.fileList.filter(x => {
            //     return x.status == 'success';
            // });
            // if (!arr.length) {
            //     this.$toast('请上传相关证据');
            //     return;
            // }
            this.loading = true;
            // arr = arr.map(x => {
            //     return x.url;
            // });
            // arr = arr.join(',');
            axios.post('/api/common/complain', {
                complaintype: this.value1,
                content: this.value2,
                user_id: this.uid,
                // url: arr
            }).then(resp => {
                let data = resp.data;
                if (data.success) {
                    this.isOk = true;
                }
            }).finally(() => {
                this.loading = false;
            });
        },
        afterRead (file) {
            let type = 0;
            if (file.file.type.indexOf('video') > -1) {
                type = 1;
            }
            file.status = 'uploading';
            file.message = '上传中...';

            let formData = new FormData();
            formData.append('name', file.file);
            formData.append('type', type);
            axios.post('/api/upload/upload', formData).then(resp => {
                let data = resp.data;
                if (data.result[0]) {
                    file.status = 'success';
                    file.url = data.result[0];
                }
            }).catch(err => {
                file.status = 'failed';
                file.message = '上传失败';
            });
        }
    }
};
</script>

<style lang='sass' scoped>
.complain-page
    padding: .48rem
    min-height: 100%
    ::v-deep .van-hairline--top-bottom::after
        border: 1px solid #dedede !important
    .title
        font-size: .48rem
        font-weight: bold
        color: #05C2CB
        padding: .8rem 0 .4rem 0
        text-align: center
    .form-box
        .item
            margin-bottom: 30px
            .label
                margin-bottom: 20px
                span.color 
                    color: red
    .result
        display: flex 
        flex-direction: column
        justify-content: center
        align-items: center
        padding-top: 2rem
        span 
            margin-bottom: .4rem
            font-size: .56rem
            font-weight: bold
            color: #05C2CB
</style>
