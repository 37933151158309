<template lang='pug'>
.pay-rights-page
    .back 
        van-icon(name="arrow-left" size="20" @click="goBack")
    .content
        .img-box 
            img(src="@/assets/rights-title.png")
            .title VIP付费包权益介绍
        .item(v-for="item in list" :key="item.name")
            .name
                .name-word {{ item.name }}
                .name-line
            .title {{ item.title }}
            .value {{ item.value }}
</template>

<script>
export default {
    data () {
        return {
            list: [
                {
                    name: '特权一', 
                    title: '个性化喂养及照护⽅案', 
                    value: '专属医⽣+专属照护团队制定个性化⽅案，实时指导，定期线上回访，VIP体验。'
                },
                {
                    name: '特权二', 
                    title: '全程⽣⻓发育监测指导', 
                    value: '体格发育监测、神经发育监测，对可能出现发育迟缓的⼊组患⼉，早发现早⼲预早治疗。'
                },
                {
                    name: '特权三', 
                    title: '全程健康监测指导', 
                    value: '常⻅疾病护理指导、疫苗接种指导、⽛⻮健康、护眼指导、就医指导。'
                },
                // {
                //     name: '特权四', 
                //     title: '专业测评量表', 
                //     value: '体格运动语言等发预评估、气质测评、社会认知商测评等'
                // },
                // {
                //     name: '特权五', 
                //     title: '专业视频课程', 
                //     value: '疾病护理课程、营养喂养课程、⽪肤护理课程、睡眠课程。'
                // },
                {
                    name: '特权四', 
                    title: '线下就诊VIP体验', 
                    value: '单独照护⻔诊、导诊服务。（挂号取号、诊前接待、诊后医嘱解释、指导预约检查、检查导诊等）。'
                }
            ]
        };
    },
    methods: {
        goBack () {
            this.$router.push('/pay');
        }
    }
};
</script>

<style lang='sass' scoped>
.pay-rights-page
    min-height: 100%
    padding: .3rem
    background: linear-gradient(180deg, #94ECD7, #DBF9E4, #E8FDED)
    .back
        margin-bottom: .4rem
    .content
        background: #fff
        border-radius: .16rem
        position: relative
        padding: .64rem .4rem .4rem .4rem
        .img-box
            position: absolute
            display: flex
            justify-content: center
            left: 0
            top: -.24rem
            width: 100%
            img
                width: 5.36rem
            .title
                position: absolute
                left: 0
                top: 0
                width: 100%
                height: .88rem 
                line-height: .88rem
                text-align: center
                font-size: 18px
                color: #71471F
                font-weight: bold
        .item
            .name
                font-size: 18px
                color: #FFDF99
                font-weight: bold
                position: relative
                height: 1rem

                .name-line
                    position: absolute
                    left: 0
                    top: .48rem
                    width: 100%
                    height: 1px
                    border-bottom: 1px dashed #EECEAF
                    z-index: 88
                .name-word
                    position: absolute
                    height: .4rem
                    line-height: .4rem
                    left: 0
                    top: .3rem
                    padding-right: .2rem
                    background: #fff
                    z-index: 99
            .title
                font-size: 16px
                font-weight: bold
                margin-bottom: .2rem
            .value
                font-size: 14px
                color: rgba(0,0,0,0.5)
</style>
