<template lang="pug">
.chart(:id='chartId')
</template>

<script>
import * as echarts from 'echarts';
export default {
    data () {
        return {
            chartId: 'chart-' + (Math.random() + '').substr(5),
            chart: null,
            throttleEvent: null,
            throttleTimer: null
        };
    },
    watch: {
        options: {
            handler () {
                this.drawChart();
            },
            deep: true
        }
    },
    props: {
        options: {}
    },
    mounted () {
        this.chart = echarts.init(document.getElementById(this.chartId), 'test');
        this.throttleEvent = this.throttle(this.onContainerResize, 200, 1000, []).bind(this);
        this.addEvent(window, 'resize', this.throttleEvent);
        this.drawChart();
    },
    beforeDestroy () {
        this.chart && this.chart.dispose();
        this.throttleEvent && this.removeEvent(window, 'resize', this.throttleEvent);
    },
    methods: {
        drawChart () {
            this.chart && this.chart.clear();
            this.chart.setOption(this.options, true);
        },
        onContainerResize () {
            this.$nextTick(() => {
                console.log('resize');
                this.chart && this.chart.resize();
            });
        },
        addEvent: (function () {
            if (window.attachEvent) {
                return function (ele, eventName, func) {
                    ele.attachEvent('on' + eventName, func);
                };
            } else {
                return function (ele, eventName, func, isCapture) {
                    ele.addEventListener(eventName, func, isCapture || false);
                };
            }
        })(),
        removeEvent: (function () {
            if (window.detach) {
                return function (ele, eventName, func) {
                    ele.detach('on' + eventName, func);
                };
            }
            return function (ele, eventName, func) {
                ele.removeEventListener(eventName, func);
            };
        })(),
        throttle (fn, delay, mustRunDelay, args) {
            this.throttleTimer = null;
            var tStart;
            return function () {
                var context = this, tCurr = + new Date();
                clearTimeout(this.throttleTimer);
                if (!tStart) {
                    tStart = tCurr;
                }
                if (tCurr - tStart >= mustRunDelay) {
                    fn.apply(context, args);
                    tStart = tCurr;
                }
                else {
                    this.throttleTimer = setTimeout(function () {
                        fn.apply(context, args);
                    }, delay);
                }
            };
        }
    }
};
</script>

<style lang="sass" scoped>
.chart
    width: 100% 
    flex: 1
</style>    

