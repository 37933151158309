<template lang='pug'>
.appraisal-check-page
    base-info(ref="info" v-if="step == 0", @confirm="startCheck")
    .wrap(v-else)
        .title-box
            .left
                van-progress(
                    color='#62C698' 
                    track-color="#A0E3C9" 
                    stroke-width="8" 
                    :pivot-text="title" 
                    :percentage="per"
                )
            .right 该项目共6题
        .check-box
            .name
                .num 0{{ current.id }}
                .word {{ current.name }}
            .viewport 观察：{{ current.viewpoint }}
            .input
                input(v-model="currentValue", placeholder="请输入")
                .unit {{ current.unit }}
            .check-bottom
                .left.disabled
                    van-icon(name="arrow-left" size="24px")
                .right(@click="nextItem")
                    van-icon(name="arrow" size="24px")
        .info-box
            .item
                .label 场地器材
                .value {{ current.site_machinery }}
            .item
                .label 场地布置
                .value {{ current.site_layout }}
            .item
                .label 示范动作
                .value
                    img(:src="current.avatar")
            .item
                .label 测试方法
                .value {{ current.test_method }}
            .item
                .label 注意事项
                .value {{ current.attention }}
</template>

<script>
import BaseInfo from './Info.vue';
import axios from 'axios';
export default {
    components: {
        BaseInfo
    },
    data () {
        return {
            channel: 1004,
            loading: false,
            step: 0, // 步骤
            title: '',
            per: 0,
            height: '',
            weight: '',
            current: {}, // 当前题目
            currentValue: '', // 当前值
            list: [], // 题目列表
            resultList: [] // 结果列表
        };
    },
    computed: {
        token () {
            return localStorage.getItem('ceping_token');
        },
        userId () {
            return this.$route.query.cp_user_id;
        }
    },
    mounted () {
        this.step = 0;
        this.title = '';
        this.per = 0;
        this.height = '';
        this.weight = '';
        this.current = {};
        this.getData();
    },
    methods: {
        getData () {
            axios.post('/api/web/Evaluation/sportItems', null, {
                headers: {
                    Authorization: 'bearer ' + this.token,
                    channel: this.channel
                }
            }).then((resp) => {
                let data = resp.data;
                if (!data.success) {
                    this.$toast(data.errors.message);
                }
                this.list = data.result.items;
            });
        },
        startCheck (obj) {
            this.height = obj.height;
            this.weight = obj.weight;
            this.current = this.list[0];
            this.step = 1;
            this.title = this.step + '/6';
            this.per = (this.step / 6).toFixed(2) * 100;
        },
        nextItem () {
            if (this.currentValue) {
                this.resultList[this.step - 1] = {
                    sport_id: this.current.id.toString(), 
                    result: this.currentValue
                };
                this.step += 1;
                if (this.step > this.list.length) {
                    this.confirmItem();
                    return;
                }
                this.currentValue = '';
                this.current = this.list[this.step - 1];
                this.title = this.step + '/6';
                this.per = (this.step / 6).toFixed(2) * 100;
            } else {
                this.$toast('请填写数据');
            }
        },
        confirmItem () {
            if (this.loading) {
                return;
            }
            this.loading = true;
            axios.post('/api/web/Evaluation/sportSaveAssessment', {
                children_id: this.userId,
                height: this.height,
                weight: this.weight,
                items: this.resultList
            }, {
                headers: {
                    Authorization: 'bearer ' + this.token,
                    channel: this.channel
                }
            }).then((resp) => {
                let data = resp.data;
                if (!data.success) {
                    this.$toast(data.errors.message);
                }
                if (data.result && data.result.children_test_id) {
                    this.$router.push('/appraisal/result2?test_id=' + data.result.children_test_id);
                }
            }).finally(() => {
                this.loading = false;
            });
        }
    }
};
</script>

<style lang='sass' scoped>
.appraisal-check-page
    height: 100%
    width: 100%
    overflow: auto
    background: #F4F8FF
    padding: .4rem
    color: #51607D
    .title-box
        width: 100%
        display: flex
        align-items: center
        font-size: 14px
        margin-bottom: .4rem
        .left
            flex: 1
        .right
            margin-left: .2rem
    .check-box
        background: #fff
        box-shadow: 0px 0px 38px 0px rgba(59,67,73,0.09)
        border-radius: .3rem
        padding: .4rem
        margin-bottom: .4rem
        .name
            display: flex
            align-items: center
            margin-bottom: .32rem
            .num
                height: .64rem
                width: .64rem
                line-height: .58rem 
                text-align: center
                border-radius: 50%
                border: 3px solid #588CF6
                color: #588CF6
                font-size: 15px
                font-weight: bold
                margin-right: .2rem
            .word
                color: #588CF6
                font-weight: bold
        .viewport
            font-size: 14px
            line-height: .48rem
            margin-bottom: .2rem
        .input
            position: relative
            input
                line-height: .8rem
                width: 100%
                padding: 0 1rem 0 .4rem
                border-radius: .4rem
                background: #F4F8FF
            .unit
                position: absolute
                width: 1rem
                text-align: center
                right: 0
                top: 0
                line-height: .8rem
                color: #9CA5B6
        .check-bottom
            display: flex
            padding: .4rem 0 .2rem 0
            justify-content: space-between
            .left,
            .right
                width: 1rem
                height: 1rem
                border-radius: 50%
                display: flex 
                justify-content: center
                align-items: center
                background: #588CF6
                color: #fff
            .disabled
                background: #F4F8FF
                color: #ADB7CD
    .info-box
        background: #fff
        box-shadow: 0px 0px 38px 0px rgba(59,67,73,0.09)
        border-radius: .3rem
        padding: .4rem
        .item
            margin-bottom: .4rem
            .label
                color: #2F3E63
                font-weight: bold
                margin-bottom: .2rem
            .value
                font-size: 14px
                line-height: .48rem
                img
                    width: 80%
                    margin-left: 10%
</style>
        