<template lang='pug'>
.pay-page
    Consent(ref="consent")
    .top
        .form
            van-field.tellphone(
                v-model="tel" 
                type="tel" 
                label="+86" 
                label-width=".9rem"
                label-class="tellphone-label"
            )
            .code-wrap
                van-field.code(v-model="code")
                .code-btn-wrap
                    .code-btn(@click="getCode" v-if="!isCountDown") 获取验证码
                    .code-btn(v-else) {{ count }}s
        .tabs
            .tab(:class="{'active2': current == 2}" @click="current = 2;") 高危儿VIP
            .tab(:class="{'active1': current == 1}" @click="current = 1;") 低危儿VIP
    .content(v-if="current == 1")
        .item(
                v-for="item in itemList1" 
                :key="item.id" 
                @click="changeItem1(item)"
                :class="{'active': item.id == currentItem1}"
            )
            .left
                .name {{ getName1(item) }}
                .money 
                    span.w1 ¥
                    span.w2 {{ item.money }}
            .right
                img(src="@/assets/vip1.png")
                .dot-wrap
                    .dot(v-if="item.id != currentItem1")
                    .dot-check(v-if="item.id == currentItem1")
                        van-icon(name="success" color="#fff" size="28")
    .content(v-if="current == 2")
        .item(
                v-for="item in itemList2" 
                :key="item.id" 
                @click="changeItem2(item)"
                :class="{'active': item.id == currentItem2}"
            )
            .left
                .name {{ getName2(item) }}
                .money 
                    span.w1 ¥
                    span.w2 {{ item.money }}
            .right
                img(src="@/assets/vip1.png")
                .dot-wrap
                    .dot(v-if="item.id != currentItem2")
                    .dot-check(v-if="item.id == currentItem2")
                        van-icon(name="success" color="#fff" size="28")
    .bottom(@click="goToRights")
        span 袋鼠照护VIP付费包权益
        img(src="@/assets/arrow-right.png")
    .footer
        .inner
            .bubble {{ getName() }}
            .left ¥{{ getMoney() }}
            .right 
                .submit(@click="submit") 立即购买
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import Consent from '@/components/Consent.vue';
export default {
    components: {
        Consent
    },
    data () {
        return {
            openId: '',
            tel: '',
            code: '',
            current: 2,
            itemList1: [],
            itemList2: [],
            currentItem1: '',
            currentItem2: '',
            callParams: {},
            loading: false,
            loading2: false,
            count: 0,
            isCountDown: false,
            timer: null
        };
    },
    async mounted () {
        // let openid = localStorage.getItem('openId');
        // if (openid) {
        //     this.openId = openid;
        // } else {
        //     if (this.$route.query.code) {
        //         let code = this.$route.query.code;
        //         let { data } = await axios.get('/api/vx/Pay/openid', {
        //             params: {
        //                 code: code
        //             }
        //         });
        //         if (!data.success) {
        //             this.$toast('openid获取失败');
        //             return;
        //         } else {
        //             this.openId = data.result.openid;
        //             localStorage.setItem('openId', data.result.openid);
        //         }
        //     }
        // }
        // if (!this.openId) {
        //     this.bindWeixin();
        // } else {
        //     this.getMoneyList();
        // }
    },
    // mounted () {
    //     this.getMoneyList();
    // },
    methods: {
        getMoneyList () {
            axios.get('/api/vx/Pay/money').then(resp => {
                let data = resp.data;
                this.itemList1 = data.result.light_risk;
                this.itemList2 = data.result.severe_risk;
                this.currentItem1 = this.itemList1[0].id;
                this.currentItem2 = this.itemList2[0].id;
            });
        },
        bindWeixin () {
            let appid = process.env.VUE_APP_SDKAPPID || 'wxb01105585f643776',
                redirectUri = encodeURIComponent(process.env.VUE_APP_BASE_URL + 'pay'),
                responseType = 'code',
                scope = 'snsapi_userinfo',
                ext = '#wechat_redirect';
            let url = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + appid 
                    + '&redirect_uri=' + redirectUri 
                    + '&response_type=' + responseType 
                    + '&scope=' + scope 
                    + '&state=true' 
                    + ext;
            window.location.href = url;
        },
        changeItem1 (obj) {
            this.currentItem1 = obj.id;
        },
        changeItem2 (obj) {
            this.currentItem2 = obj.id;
        },
        goToRights () {
            this.$router.push('/pay-rights');
        },
        getMoney () {
            if (this.current == 1) {
                let res = this.itemList1.find(x => {
                    return x.id == this.currentItem1;
                });
                if (!res) {
                    return '';
                }
                return res.money;
            } else {
                let res = this.itemList2.find(x => {
                    return x.id == this.currentItem2;
                });
                if (!res) {
                    return '';
                }
                return res.money;
            }
        },
        getName () {
            if (this.current == 1) {
                let res = this.itemList1.find(x => {
                    return x.id == this.currentItem1;
                });
                if (!res) {
                    return '';
                }
                if (res.service == 1) {
                    return '一年VIP付费包';
                } else if (res.service == 2) {
                    return '半年VIP付费包';
                } else if (res.service == 3) {
                    return '单月体验VIP付费包';
                }
            } else {
                let res = this.itemList2.find(x => {
                    return x.id == this.currentItem2;
                });
                if (!res) {
                    return '';
                }
                if (res.service == 1) {
                    return '一年VIP付费包';
                } else if (res.service == 2) {
                    return '半年VIP付费包';
                } else if (res.service == 3) {
                    return '单月体验VIP付费包';
                }
            }
        },
        getName1 (obj) {
            let res = this.itemList1.find(x => {
                return x.id == obj.id;
            });
            if (res.service == 1) {
                return '一年VIP付费包';
            } else if (res.service == 2) {
                return '半年VIP付费包';
            } else if (res.service == 3) {
                return '单月体验VIP付费包';
            }
        },
        getName2 (obj) {
            let res = this.itemList2.find(x => {
                return x.id == obj.id;
            });
            if (res.service == 1) {
                return '一年VIP付费包';
            } else if (res.service == 2) {
                return '半年VIP付费包';
            } else if (res.service == 3) {
                return '单月体验VIP付费包';
            }
        },
        submit () {
            // this.$refs.consent.show();
            if (this.loading2) {
                this.$toast('下单中，请勿重复点击');
                return;
            }
            if (!this.openId) {
                return;
            }
            if (!this.tel) {
                this.$toast('请填写手机号');
                return;
            }
            if (!this.code) {
                this.$toast('请输入验证码');
                return;
            }
            this.callParams = {};
            let params = {
                openid: this.openId,
                cellphone: this.tel,
                v_code: this.code
            };
            if (this.current == 1) {
                let res = this.itemList1.find(x => {
                    return x.id == this.currentItem1;
                });
                params.current_money = res.calculation_money;
                params.money_id = this.currentItem1;
            } else {
                let res = this.itemList2.find(x => {
                    return x.id == this.currentItem2;
                });
                params.current_money = res.calculation_money;
                params.money_id = this.currentItem2;
            }
            this.loading2 = true;
            axios.post('/api/vx/Pay/order', params).then(resp => {
                let data = resp.data;
                if (data.success) {
                    this.callParams = data.result;
                    this.$nextTick(() => {
                        this.callWeixinPay();
                    });
                } else {
                    let err = _.get(data, 'errors.message', '未知错误');
                    this.$toast(err);
                }
            }).finally(() => {
                this.loading2 = false;
            });
        },
        callWeixinPay () {
            if (typeof WeixinJSBridge == "undefined") {
                if (document.addEventListener) {
                    document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
                } else if (document.attachEvent) {
                    document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady);
                    document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady);
                }
            } else {
                this.onBridgeReady();
            }
        },
        onBridgeReady () {
            WeixinJSBridge.invoke('getBrandWCPayRequest', this.callParams, (res) => {
                if (res.err_msg == "get_brand_wcpay_request:ok") {
                    this.$router.push('/pay-over');
                }
            });
        },
        getCode () {
            if (this.loading) {
                return;
            }
            if (!this.tel) {
                this.$toast('请输入手机号');
                return;
            } else {
                let flag = this.checkPhoneNum();
                if (!flag) {
                    this.$toast('手机号格式不正确');
                    return;
                }
            }
            this.loading = true;
            axios.post('/api/user/send_verify_code', {
                cellphone: this.tel,
                channel: '1001'
            }).then(resp => {
                let data = resp.data;
                if (data.success) {
                    this.$toast('获取验证码成功');
                    this.count = 60;
                    this.isCountDown = true;
                    this.timer = setInterval(() => {
                        if (this.count <= 0) {
                            clearInterval(this.timer);
                            this.isCountDown = false;
                        } else {
                            this.count -= 1;
                        }
                    }, 1000);
                }
            }).finally(() => {
                this.loading = false;
            });
        },
        checkPhoneNum () {
            let myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
            if (!myreg.test(this.tel)) {
                return false;
            } else {
                return true;
            }
        },
    }
};
</script>

<style lang='sass' scoped>
.pay-page
    position: relative
    .footer
        position: fixed
        bottom: 0
        left: 0
        width: 100%
        height: 1.4rem
        padding: 0 .3rem
        background: #fff
        display: flex
        align-items: center
        z-index: 99
        .inner 
            height: 1rem
            width: 100%
            background-image: url(../assets/bottom.png)
            background-size: 100% 100%
            display: flex
            position: relative
            .left
                flex: 1
                line-height: 1rem
                color: #EC6509
                font-size: 28px
                font-weight: bold
                padding-left: .4rem
            .right
                .submit
                    background: linear-gradient(90deg, #FF581D, #FF913D)
                    height: .8rem
                    color: #fff
                    padding: 0 .48rem
                    line-height: .8rem
                    border-radius: .4rem
                    font-size: 18px
                    margin-right: .1rem
                    margin-top: .1rem
            .bubble
                position: absolute
                left: .28rem
                top: -.2rem
                font-size: .24rem
                height: .4rem
                line-height: .4rem
                border-radius: .2rem
                border-bottom-left-radius: 0
                background: linear-gradient(90deg, #05C2CB, #E9FDE9)
                padding: 0 .2rem
                color: #000
    .bottom 
        display: flex
        height: .88rem
        justify-content: flex-end
        align-items: center
        font-size: 14px
        color: rgba(0,0,0,0.5)
        padding: 0 .3rem
        margin-bottom: 1.4rem
        img
            height: .3rem
            width: .3rem
    .content
        padding: .16rem .3rem 0 .3rem
        .item:last-child
            margin-bottom: 0
        .item.active
            border: 2px solid #fff
            background: linear-gradient(90deg, #EEFCF9, #D3FFF5)
            box-shadow: 0px 5px 12px -1px rgba(99,127,120,0.31)
        .item
            border: 2px solid #EEEEEE
            margin-bottom: .16rem
            border-radius: .12rem
            display: flex
            justify-content: space-between
            height: 1.8rem
            align-items: center
            padding: 0 .2rem 0 .5rem
            .left
                .name 
                    font-size: 18px
                    font-weight: bold
                    margin-bottom: .08rem
                .money
                    color: #EC6509
                    font-size: 32px
                    font-weight: bold
                    .w1
                        font-size: 20px
                    
            .right
                position: relative
                img
                    width: 2.5rem
                    margin-top: .3rem
                .dot-wrap
                    position: absolute
                    right: .3rem
                    top: .5rem
                    height: .8rem 
                    width: .8rem
                    .dot
                        height: .8rem
                        width: .8rem
                        border: 2px solid #E0E0E0
                        border-radius: 50%
                        background: #fff
                    .dot-check
                        height: .8rem
                        width: .8rem
                        border: 2px solid #fff
                        border-radius: 50%
                        background: #DD7A37
                        display: flex 
                        justify-content: center
                        align-items: center
    .top
        width: 100%
        height: 6.92rem
        background-image: url(../assets/bg.png)
        background-size: 100% 100%
        padding-top: 4.16rem
        position: relative
        .form
            padding: 0 .6rem
        .tellphone
            border-radius: .44rem
            font-size: 18px
            padding: 6px 16px
            margin-bottom: .2rem
            font-weight: bold
            ::v-deep .tellphone-label
                color: #222222
                border-right: 1px solid #222222
        .code-wrap
            display: flex
            width: 100%
            .code-btn-wrap
                width: 2.5rem
                height: .72rem
                background-image: url(../assets/code-btn.png)
                background-size: 100% 100%
                text-align: center
                line-height: .72rem
                color: #582C0A
                font-weight: bold
            ::v-deep .code
                flex: 1
                font-size: 18px
                font-weight: bold
                padding: 6px 16px
                color: #222222
                background-color: transparent
                background-image: url(../assets/code.png)
                background-size: 100% 100%
        .tabs   
            display: flex
            height: .8rem
            width: 100%
            line-height: .8rem
            text-align: center
            position: absolute
            bottom: 0
            left: 0
            .tab
                flex: 1
                color: #005457
                font-size: 18px
                font-weight: 600
            .active1
                color: #E66F1C
                background-image: url(../assets/tab.png)
                background-size: 100% 100%
            .active2
                color: #E66F1C
                background-image: url(../assets/tab2.png)
                background-size: 100% 100%
                
</style>
