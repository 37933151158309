<template lang='pug'>
.appraisal-result-page
    van-skeleton(
        title,
        avatar,
        :loading="loading"
        :row="10"
    )
        .top-title
            .back(@click="goBack" v-if="isBack") 返回
            .w1 宝贝运动发育评估报告
        .user-info 
            .top
                .left
                    van-image(
                        round
                        width="1rem"
                        height="1rem"
                        :src="result.avatar"
                    )
                .right
                    .name {{ result.children_nickname }}
                    .gender 
                        span.sex {{ result.sex | filterSex }}
                        span {{ result.corrected_month_age_text || result.month_age_text }}
                    .info
                        span.first 身高{{ result.height }}cm
                        span 体重{{ result.weight }}kg
            .bottom 测评时间：{{ result.created_at }}
        .line
        .height-box.box
            .title 身高分析
            .content
                .chart-wrap
                    chart(:options='option1')
                .word-wrap
                    .from(style="margin-bottom: 0") {{ result.height_data_source }}
                    .from {{ result.height_norm_base_on == 1 ?  "根据生理月龄分析" : "根据矫正月龄分析" }}
                    .suggest 
                        .suggest-title 身高建议
                        span {{ result.height_suggest }}
        .line
        .weight-box.box
            .title 体重分析
            .content
                .chart-wrap
                    chart(:options='option2')
                .word-wrap
                    .from(style="margin-bottom: 0") {{ result.weight_data_source }}
                    .from {{ result.height_norm_base_on == 1 ?  "根据生理月龄分析" : "根据矫正月龄分析" }}
                    .suggest 
                        .suggest-title 体重建议
                        span {{ result.weight_suggest }}
        .line
        .jx-box.box
            .title 精细动作发展
            .content
                .chart-wrap
                    chart(:options='option3')
                .word-wrap
                    .from(style="margin-bottom: 0") {{ result.jx_data_source }}
                    .from {{ result.jx_norm_base_on == 1 ?  "根据生理月龄分析" : "根据矫正月龄分析" }}
                    .suggest
                        .suggest-chart 
                            .left
                                .w1 精细动作超过该年龄段{{ result.jx_percent }}%的孩子
                                .w2 运动能力：{{ result.jx_level | filterLevel }}
                            .right
                                van-circle(
                                    v-model:current-rate="currentRate"
                                    :rate="currentRate"
                                    :speed="100"
                                    size="100"
                                    stroke-width="100"
                                    :text="currentRate + '%'"
                                    :color={
                                        '0%': '#5B8EF7',
                                        '100%': '#19B5F6',
                                    }
                                    layer-color="#BCC7DC"
                                )
                        .suggest-title 教育指导建议
                        .list(v-for="item in result.jx_suggest")
                            .item
                                span(style="font-weight: bold;") · 
                                span {{ item.suggest }}
        .line
        .cd-box.box
            .title 粗大动作发展
            .content
                .chart-wrap
                    chart(:options='option4')
                .word-wrap
                    .from(style="margin-bottom: 0") {{ result.cd_data_source }}
                    .from {{ result.cd_norm_base_on == 1 ?  "根据生理月龄分析" : "根据矫正月龄分析" }}
                    .suggest
                        .suggest-chart 
                            .left
                                .w1 粗大动作超过该年龄段{{ result.cd_percent }}%的孩子
                                .w2 运动能力：{{ result.cd_level | filterLevel }}
                            .right
                                van-circle(
                                    v-model:current-rate="currentRate2"
                                    :rate="currentRate2"
                                    :speed="100"
                                    size="100"
                                    stroke-width="100"
                                    :text="currentRate2 + '%'"
                                    :color={
                                        '0%': '#5B8EF7',
                                        '100%': '#19B5F6',
                                    }
                                    layer-color="#BCC7DC"
                                )
                        .suggest-title 教育指导建议
                        .list(v-for="item in result.cd_suggest")
                            .item
                                span(style="font-weight: bold;") · 
                                span {{ item.suggest }}
        .line
        .capabilities-box.box.mb
            .title 动作能力分析
            .content
                .cap-list 
                    .item(v-for="item in capabilities")
                        .label {{ item.name }}
                        .value
                            .value-inner(:style="'width: ' + ((item.score / item.total) * 100).toFixed(2) + '%'")
                .word-wrap
                    .suggest 
                        .suggest-title 动作能力指导建议
                        .list(v-for="item in result.capability_suggest")
                            .item
                                span(style="font-weight: bold;") · 
                                span {{ item.suggest }}
        .family-box.box2.mb
            .common-title 
                .inner 家庭训练建议
            .content {{ result.family_suggest }}
        .video-box.box2.mb
            .common-title 
                .inner 互动指导视频
            .video-list 
                .video-item(v-for="item in videoList" @click="goToVideoDetail(item)")
                    .left
                        img(:src="item.avatar")
                    .right
                        .right-name {{ item.title }}
                        .right-tip {{ item.physiology_aim }}
                .common-btn(@click="checkAllVideo") 查看全部视频 
        .support-box.box2
            .common-title 
                .inner 支持单位
            .support-list 
                .support-item(v-for="item in supportList")
                    .left
                        img(:src="item.logo")
                    .right {{ item.title }}
</template>

<script>
import * as echarts from 'echarts';
import Chart from '@/components/Chart.vue';
import axios from 'axios';
export default {
    components: {
        Chart
    },
    data () {
        return {
            channel: 1004,
            loading: false,
            result: {},
            option1: {},
            option2: {},
            option3: {},
            option4: {},
            currentRate: 0,
            currentRate2: 0,
            capabilities: [],
            videoList: [],
            supportList: [],
            num: 0
        };
    },
    computed: {
        token () {
            return localStorage.getItem('ceping_token');
        },
        testId () {
            return this.$route.query.test_id;
        },
        isBack () {
            return this.$route.query.is_back;
        }
    },
    filters: {
        filterSex (res) {
            if (res == 1) {
                return '男';
            } else {
                return '女';
            }
        },
        filterLevel (val) {
            let dic = {
                1: '极差',
                2: '偏差',
                3: '中等',
                4: '偏好',
                5: '极好'
            };
            return dic[val];
        }
    },
    mounted () {
        this.getDetail();
    },
    methods: {
        getDetail () {
            this.loading = true;
            axios.post('/api/web/Evaluation/growthTestDetail', {
                id: this.testId
            }, {
                headers: {
                    Authorization: 'bearer ' + this.token,
                    channel: this.channel
                }
            }).then((resp) => {
                let data = resp.data;
                if (!data.success) {
                    this.$toast(data.errors.message);
                }
                if (data && data.result) {
                    this.result = data.result;
                    this.setData();
                    this.loading = false;
                } else {
                    this.num += 1;
                    setTimeout(() => {
                        if (this.num < 10) {
                            this.getDetail();
                        } else {
                            this.$toast('获取结果已超时');
                        }
                    }, 2000);
                }
            }).catch(() => {
                this.loading = false;
            });
        },
        setData () {
            this.option1 = {
                series: [
                    {
                        type: 'gauge',
                        startAngle: 180,
                        endAngle: 0,
                        center: ['50%', '82%'],
                        radius: '100%',
                        min: 0,
                        max: 1,
                        splitNumber: 10,
                        axisLine: {
                            roundCap: true,
                            lineStyle: {
                                width: 10,
                                color: [
                                    [1, new echarts.graphic.LinearGradient(0, 0, 1, 0, 
                                        [
                                            {
                                                offset: 0,
                                                color: "#FFE1EC"
                                            },
                                            {
                                                offset: 1,
                                                color: "#588BF2"
                                            }
                                        ])
                                    ]
                                ]
                            }
                        },
                        pointer: {
                            length: '25%',
                            offsetCenter: [0, '-65%'],
                            itemStyle: {
                                color: '#5B8EF7'
                            }
                        },
                        axisTick: {
                            length: 10,
                            lineStyle: {
                                color: '#E7E6E6',
                                width: 1
                            }
                        },
                        splitLine: {
                            show: false
                        },
                        axisLabel: {
                            fontSize: 12,
                            distance: -54,
                            formatter: function (value) {
                                if (value === 0) {
                                    return '矮小';
                                } else if (value === 0.2) {
                                    return '偏低';
                                } else if (value === 0.5) {
                                    return '中等';
                                } else if (value === 0.8) {
                                    return '偏高';
                                } else if (value === 1) {
                                    return '超高';
                                }
                                return '';
                            }
                        },
                        title: {
                            offsetCenter: [0, '-5%'],
                            fontSize: 12,
                            color: '#9CA5B6'
                        },
                        detail: {
                            fontSize: 18,
                            offsetCenter: [0, '-25%'],
                            valueAnimation: true,
                            formatter: function (value) {
                                return this.result.height + 'cm';
                            }.bind(this),
                            color: '#2F3E63'
                        },
                        data: [
                            {
                                value: this.getLevel(this.result.height_level),
                                name: '身高'
                            }
                        ]
                    }
                ]
            };
            this.option2 = {
                series: [
                    {
                        type: 'gauge',
                        startAngle: 180,
                        endAngle: 0,
                        center: ['50%', '82%'],
                        radius: '100%',
                        min: 0,
                        max: 1,
                        splitNumber: 10,
                        axisLine: {
                            roundCap: true,
                            lineStyle: {
                                width: 10,
                                color: [
                                    [1, new echarts.graphic.LinearGradient(0, 0, 1, 0, 
                                        [
                                            {
                                                offset: 0,
                                                color: "#FFE1EC"
                                            },
                                            {
                                                offset: 1,
                                                color: "#588BF2"
                                            }
                                        ])
                                    ]
                                ]
                            }
                        },
                        pointer: {
                            length: '25%',
                            offsetCenter: [0, '-65%'],
                            itemStyle: {
                                color: '#5B8EF7'
                            }
                        },
                        axisTick: {
                            length: 10,
                            lineStyle: {
                                color: '#E7E6E6',
                                width: 1
                            }
                        },
                        splitLine: {
                            show: false
                        },
                        axisLabel: {
                            fontSize: 12,
                            distance: -54,
                            formatter: function (value) {
                                if (value === 0) {
                                    return '消瘦';
                                } else if (value === 0.2) {
                                    return '偏轻';
                                } else if (value === 0.5) {
                                    return '中等';
                                } else if (value === 0.8) {
                                    return '偏重';
                                } else if (value === 1) {
                                    return '超重';
                                }
                                return '';
                            }
                        },
                        title: {
                            offsetCenter: [0, '-5%'],
                            fontSize: 12,
                            color: '#9CA5B6'
                        },
                        detail: {
                            fontSize: 18,
                            offsetCenter: [0, '-25%'],
                            valueAnimation: true,
                            formatter: function (value) {
                                return this.result.weight + 'kg';
                            }.bind(this),
                            color: '#2F3E63'
                        },
                        data: [
                            {
                                value: this.getLevel(this.result.weight_level),
                                name: '体重'
                            }
                        ]
                    }
                ]
            };
            this.option3 = {
                series: [
                    {
                        type: 'gauge',
                        startAngle: 180,
                        endAngle: 0,
                        center: ['50%', '82%'],
                        radius: '100%',
                        min: 0,
                        max: 1,
                        splitNumber: 10,
                        axisLine: {
                            roundCap: true,
                            lineStyle: {
                                width: 10,
                                color: [
                                    [1, new echarts.graphic.LinearGradient(0, 0, 1, 0, 
                                        [
                                            {
                                                offset: 0,
                                                color: "#FFE1EC"
                                            },
                                            {
                                                offset: 1,
                                                color: "#588BF2"
                                            }
                                        ])
                                    ]
                                ]
                            }
                        },
                        pointer: {
                            length: '25%',
                            offsetCenter: [0, '-65%'],
                            itemStyle: {
                                color: '#5B8EF7'
                            }
                        },
                        axisTick: {
                            length: 10,
                            lineStyle: {
                                color: '#E7E6E6',
                                width: 1
                            }
                        },
                        splitLine: {
                            show: false
                        },
                        axisLabel: {
                            fontSize: 12,
                            distance: -54,
                            formatter: function (value) {
                                if (value === 0) {
                                    return '极差';
                                } else if (value === 0.2) {
                                    return '偏差';
                                } else if (value === 0.5) {
                                    return '中等';
                                } else if (value === 0.8) {
                                    return '偏好';
                                } else if (value === 1) {
                                    return '极好';
                                }
                                return '';
                            }
                        },
                        title: {
                            offsetCenter: [0, '-5%'],
                            fontSize: 12,
                            color: '#9CA5B6'
                        },
                        detail: {
                            fontSize: 18,
                            offsetCenter: [0, '-25%'],
                            valueAnimation: true,
                            formatter: function (value) {
                                return this.result.jx_score + '';
                            }.bind(this),
                            color: '#2F3E63'
                        },
                        data: [
                            {
                                value: this.getLevel(this.result.jx_level),
                                name: '得分'
                            }
                        ]
                    }
                ]
            };
            this.option4 = {
                series: [
                    {
                        type: 'gauge',
                        startAngle: 180,
                        endAngle: 0,
                        center: ['50%', '82%'],
                        radius: '100%',
                        min: 0,
                        max: 1,
                        splitNumber: 10,
                        axisLine: {
                            roundCap: true,
                            lineStyle: {
                                width: 10,
                                color: [
                                    [1, new echarts.graphic.LinearGradient(0, 0, 1, 0, 
                                        [
                                            {
                                                offset: 0,
                                                color: "#FFE1EC"
                                            },
                                            {
                                                offset: 1,
                                                color: "#588BF2"
                                            }
                                        ])
                                    ]
                                ]
                            }
                        },
                        pointer: {
                            length: '25%',
                            offsetCenter: [0, '-65%'],
                            itemStyle: {
                                color: '#5B8EF7'
                            }
                        },
                        axisTick: {
                            length: 10,
                            lineStyle: {
                                color: '#E7E6E6',
                                width: 1
                            }
                        },
                        splitLine: {
                            show: false
                        },
                        axisLabel: {
                            fontSize: 12,
                            distance: -54,
                            formatter: function (value) {
                                if (value === 0) {
                                    return '极差';
                                } else if (value === 0.2) {
                                    return '偏差';
                                } else if (value === 0.5) {
                                    return '中等';
                                } else if (value === 0.8) {
                                    return '偏好';
                                } else if (value === 1) {
                                    return '极好';
                                }
                                return '';
                            }
                        },
                        title: {
                            offsetCenter: [0, '-5%'],
                            fontSize: 12,
                            color: '#9CA5B6'
                        },
                        detail: {
                            fontSize: 18,
                            offsetCenter: [0, '-25%'],
                            valueAnimation: true,
                            formatter: function (value) {
                                return this.result.cd_score + '';
                            }.bind(this),
                            color: '#2F3E63'
                        },
                        data: [
                            {
                                value: this.getLevel(this.result.cd_level),
                                name: '得分'
                            }
                        ]
                    }
                ]
            };
            this.currentRate = this.result.jx_percent;
            this.currentRate2 = this.result.cd_percent;
            this.capabilities = this.result.capabilities.capabilities;
            this.videoList = this.result.videos;
            this.supportList = this.result.supporters;
        },
        getLevel (num) {
            let obj = {
                1: 0,
                2: 0.2,
                3: 0.5,
                4: 0.8,
                5: 1
            };
            return obj[num];
        },
        checkAllVideo () {
            this.$router.push('/appraisal/video-list?test_id=' + this.testId);
        },
        goToVideoDetail (item) {
            this.$router.push('/appraisal/video-detail?id=' + item.id);
        },
        goBack () {
            this.$router.back();
        }
    }
};
</script>

<style lang='sass' scoped>
.common-btn
    height: .8rem
    border-radius: .5rem
    background: #588CF6
    color: #fff
    font-size: 16px
    font-weight: bold
    text-align: center
    display: flex
    justify-content: center
    align-items: center
    margin: .4rem 0 .2rem 0
.mb
    margin-bottom: .4rem
.line
    height: .4rem
    width: 100%
    position: relative
.line::after
    content: ''
    position: absolute
    height: .4rem 
    width: .2rem
    background: #5B8EF7
    left: 20%
    top: 0
.line::before
    content: ''
    position: absolute
    height: .4rem 
    width: .2rem
    background: #5B8EF7
    right: 20%
    top: 0
.appraisal-result-page
    background: #F4F8FF
    padding: .4rem
    min-height: 100%
    color: #51607D
    .top-title
        margin-bottom: .4rem
        text-align: center
        position: relative
        .back
            position: absolute
            left: 0 
            top: 0
            height: 100%
            font-size: 14px
            color: #5B8EF7
            padding-top: 3px
        .w1
            font-size: 20px
            font-weight: bold
        .w2
            color: #A0E3C9
            font-size: 20px
            font-weight: bold
    .user-info
        background: #fff
        box-shadow: 0px 0px 38px 0px rgba(59,67,73,0.09)
        border-radius: .3rem
        padding: .4rem
        .top
            display: flex
            margin-bottom: .2rem
            .left
                margin-right: .32rem
            .right
                .name
                    font-weight: bold
                    margin-bottom: .2rem
                .gender
                    display: flex
                    align-items: center
                    margin-bottom: .2rem
                    font-size: 13px
                    .sex
                        background: #588CF6
                        color: #fff
                        font-size: 13px
                        line-height: .4rem
                        height: .42rem
                        padding: 0 .1rem
                        border-radius: .1rem
                        margin-right: .1rem
                .info
                    margin-bottom: .1rem
                    font-size: 13px
                    .first 
                        margin-right: .2rem
        .bottom
            background: #ECF1FE
            color: #588BF3
            height: .64rem
            line-height: .64rem
            text-align: center
            border-radius: .32rem
            font-size: 13px
    .box
        background: #fff
        box-shadow: 0px 0px 38px 0px rgba(59,67,73,0.09)
        border-radius: .3rem
        overflow: hidden
        .title
            background: #5B8EF7
            color: #fff
            height: .8rem
            line-height: .8rem
            padding: 0 .4rem
            font-weight: bold
        .content
            .cap-list
                padding: .5rem .4rem .3rem .4rem
                .item
                    display: flex 
                    align-items: center
                    margin-bottom: .2rem
                    .label
                        width: 1.4rem
                        font-size: 13px
                        color: #51607D
                    .value
                        flex: 1
                        background: #E4EAF4
                        height: .2rem
                        border-radius: .1rem
                        position: relative
                        .value-inner
                            height: .2rem
                            position: absolute
                            left: 0
                            top: 0
                            border-radius: .1rem
                            background: #5B8EF7
            .chart-wrap
                display: flex
                height: 4rem
            .word-wrap
                padding: 0 .4rem .4rem .4rem
                .from 
                    text-align: center
                    font-size: 12px 
                    color: #9CA5B6
                    margin-bottom: .4rem
                    white-space: pre
                .suggest
                    border: 1px solid #E4EAF4
                    border-radius: .3rem
                    padding: .2rem .3rem
                    color: #51607D
                    font-size: 12px
                    line-height: .48rem
                    .suggest-title 
                        color: #588BF2
                        font-weight: bold
                        font-size: 13px
                    .suggest-chart 
                        display: flex 
                        .left
                            flex: 1
                            margin-right: .4rem
                            .w1
                                color: #2F3E63
                                font-size: 12px
                                font-weight: bold
                            .w2
                                font-size: 12px
                                color: #9CA5B6
                        .right 
                            width: 2.2rem
                            height: 2.2rem
    .box2
        background: #fff
        box-shadow: 0px 0px 38px 0px rgba(59,67,73,0.09)
        border-radius: .3rem
        overflow: hidden
        .common-title
            height: .8rem
            display: flex
            justify-content: center
            margin-bottom: .4rem
            .inner
                background: #588CF6
                border-bottom-left-radius: .8rem
                border-bottom-right-radius: .8rem
                width: 3rem
                height: .8rem
                text-align: center
                line-height: .8rem
                font-size: 16px 
                font-weight: bold
                color: #fff
        .content
            padding: 0 .4rem .4rem .4rem
            font-size: 12px
            line-height: .48rem
    .video-box
        .video-list
            padding: 0 .4rem .4rem .4rem
            .video-item
                display: flex
                margin-bottom: .3rem
                .left
                    width: 3rem
                    border-radius: .2rem
                    overflow: hidden
                    margin-right: .4rem
                    img
                        width: 3rem
                .right
                    flex: 1
                    display: flex
                    flex-direction: column
                    justify-content: center
                    .right-name
                        font-size: 14px
                        margin-bottom: .2rem
                        font-weight: bold
                    .right-tip
                        font-size: 12px
                        color: #9CA5B6
            .video-item:last-child
                margin-bottom: 0
    .support-list
        padding: 0 .4rem .4rem .4rem
        .support-item
            display: flex 
            align-items: center
            margin-bottom: .2rem
            .left
                width: 1rem
                margin-right: .4rem
                img
                    width: 1rem
            .right
                font-size: 15px
</style>