<template lang='pug'>
.appraisal-index-page 
    van-skeleton(
        title,
        :loading="loading"
        :row="10"
    )
        .title 建议场地及必要设施
        .list-wrap.mb-4(v-if="type == 1")
            .list-title 
                .inner 0~1岁
            .list
                .item(v-for="item in list1" :key="item.name")
                    .item-icon
                        img(:src="item.icon")
                    .item-name {{ item.name }}
        .list-wrap.mb-4(v-if="type == 1")
            .list-title 
                .inner 1~3岁
            .list
                .item(v-for="item in list2" :key="item.name")
                    .item-icon
                        img(:src="item.icon")
                    .item-name {{ item.name }}
        .list-wrap.mb-4(v-if="type == 2")
            .list-title 
                .inner 3~6岁
            .list
                .item(v-for="item in list3" :key="item.name")
                    .item-icon(v-if="item.icon")
                        img(:src="item.icon")
                    .item-name {{ item.name }}
        .tip 请准备好工具开始测评
        .submit-btn(@click="goToCheck" :class="{'disabled': restNum <= 0}") 开始测评

</template>

<script>
import axios from 'axios';
import moment from 'moment';
export default {
    data () {
        return {
            channel: 1004,
            loading: false,
            restNum: 0,
            list1: [],
            list2: [],
            list3: [],
            type: '',
            token: '',
            dsUserId: '',  // 袋鼠宝宝id
            cpUserId: ''   // 测评方宝宝id
        };
    },
    mounted () {
        this.token = this.$route.query.token;
        this.dsUserId = this.$route.query.ds_user_id;
        if (!this.token || !this.dsUserId) {
            this.$toast('缺少必要参数');
            return;
        } else {
            // 将token存入本地
            localStorage.setItem('ceping_token', this.token);
            // 获取宝宝详情
            this.$nextTick(() => {
                this.getData();
            });
        }
    },
    methods: {
        getData () {
            this.loading = true;
            axios.post('/api/web/Evaluation/babyDetails', {
                user_id: this.dsUserId
            }, {
                headers: {
                    Authorization: 'bearer ' + this.token,
                    channel: this.channel
                }
            }).then((resp) => {
                let data = resp.data;
                if (data.success) {
                    this.getToolData(data.result.birthday);
                    this.cpUserId = data.result.id;
                    this.checkProcess(data);
                    this.restNum = data.result.assessment_number;
                } else {
                    this.$toast(data.errors.message);
                    this.loading = false;
                }
            }).catch(() => {
                this.loading = false;
            });
        },
        getToolData (birthday) {
            if (!birthday) {
                this.loading = false;
                this.$toast('无法计算年龄');
                return;
            }
            let days = moment().diff(moment(birthday), 'days');
            if (days > 365 * 3) {
                this.type = 2;
            } else {
                this.type = 1;
            }
            if (this.type == 1) {
                axios.post('/api/web/Evaluation/growthPrepareTools', null, {
                    headers: {
                        Authorization: 'bearer ' + this.token,
                        channel: this.channel
                    }
                }).then((resp) => {
                    // console.log(resp);
                    let data = resp.data.result || {};
                    this.list1 = data.tools_1;
                    this.list2 = data.tools_2;
                }).finally(() => {
                    this.loading = false;
                });
            }
            if (this.type == 2) {
                axios.post('/api/web/Evaluation/sportPrepareTools', null, {
                    headers: {
                        Authorization: 'bearer ' + this.token,
                        channel: this.channel
                    }
                }).then((resp) => {
                    console.log(resp);
                    let data = resp.data.result || {};
                    data.tools.push({});
                    this.list3 = data.tools;
                }).finally(() => {
                    this.loading = false;
                });
            }
        },
        goToCheck () {
            if (this.restNum <= 0) {
                this.$toast('没有测评次数');
                return;
            }
            if (this.type == 1 && this.cpUserId) {
                this.$router.push('/appraisal/check1?cp_user_id=' + this.cpUserId);
            }
            if (this.type == 2 && this.cpUserId) {
                this.$router.push('/appraisal/check2?cp_user_id=' + this.cpUserId);
            }
        },
        checkProcess (data) {
            if (data.result.is_growth_test === 0 || data.result.is_growth_test === '0') {
                if (!data.result.question.id) {
                    return;
                }
                this.$store.commit('appraisal/setQuestion', data.result.question);
                this.$dialog.confirm({
                    message: '有未完成的答题，是否继续？',
                    confirmButtonColor: '#588CF6'
                }).then(() => {
                    this.$router.push('/appraisal/check1?cp_user_id=' + this.cpUserId + '&continue_test_id=' + data.result.children_test_id);
                }).catch(() => {
                    axios.post('/api/web/Evaluation/growthTestComeBack', {
                        children_test_id: data.result.children_test_id
                    }, {
                        headers: {
                            Authorization: 'bearer ' + this.token,
                            channel: this.channel
                        }
                    }).then((resp) => {
                        let data = resp.data;
                        if (!data.success) {
                            this.$toast(data.errors.message);
                        }
                    });
                });
            }
        }
    }
};
</script>

<style lang='sass' scoped>
.mb-4
    margin-bottom: .4rem
.appraisal-index-page
    padding: .4rem
    background: #F4F8FF
    min-height: 100%
    .title
        color: #2F3E63
        font-size: 18px
        font-weight: 600
        margin-bottom: .4rem
        padding-left: .2rem
        position: relative
    .title::after
        content: ''
        position: absolute
        left: 0
        top: 0
        height: 100%
        width: .1rem
        background: #588CF6
        border-radius: .06rem
    .list-wrap
        background: #fff
        box-shadow: 0px 0px 38px 0px rgba(59,67,73,0.09)
        border-radius: .3rem
        padding: 0 .6rem .2rem .6rem
        min-height: 2rem
        .list-title
            height: .6rem
            display: flex
            justify-content: center
            margin-bottom: .4rem
            .inner
                background: #588CF6
                border-bottom-left-radius: .6rem
                border-bottom-right-radius: .6rem
                width: 3rem
                height: .6rem
                text-align: center
                line-height: .6rem
                font-size: 16px 
                font-weight: bold
                color: #fff
        .list
            display: flex
            flex-wrap: wrap
            justify-content: space-between
            .item
                width: 1.2rem
                min-height: 1rem
                margin-bottom: .4rem
                .item-icon
                    display: flex
                    justify-content: center
                    margin-bottom: .1rem
                    img
                        width: .6rem
                        height: .6rem
                .item-name 
                    font-size: 12px
                    text-align: center
    .tip
        font-size: 12px
        color: #9CA5B6
        text-align: center
        margin-bottom: .2rem
    .submit-btn
        height: .8rem
        border-radius: .5rem
        background: #588CF6
        color: #fff
        font-size: 16px
        font-weight: bold
        text-align: center
        line-height: .8rem
    .disabled
        opacity: 0.5
</style>
    