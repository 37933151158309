<template>
    <div class="sign-content" id="sign-content">
        <canvas 
            ref="signHandle" 
            class="canvas" 
            id="canvas" 
            @touchstart="drawStart" 
            @touchmove="drawing" 
            @touchend="drawEnd"
        ></canvas>
    </div>
</template>
<script>
const { clientWidth, clientHeight } = document.documentElement;
import html2Canvas from 'html2canvas';
import axios from 'axios';
export default {
    props: {
        //canvas 宽度
        canvasWidth: {
            type: Number,
            default: clientWidth - 22,
        },
        //canvas 高度
        canvasHeight: {
            type: Number,
            default: clientHeight,
        },
        //canvas 背景色
        canvasBackground: {
            type: String,
            default: "#fff",
        },
        //线条颜色
        lineColor: {
            type: String,
            default: "#000",
        },
        //线条宽度
        lineWidth: {
            type: Number,
            default: 3,
        },
        //线条两端形状
        lineRound: {
            type: String,
            default: "round", //圆形
        },
    },
    data () {
        return {
            loading: false,
            el: "", //canvas dom
            ctx: "", //canvas内容
            heightTop: 0
        };
    },
    mounted () {
        this.draw();
    },
    methods: {
        addWindowScroll () {
            document.addEventListener("touchmove", this.handleWindowScroll, {
                passive: false,
            });
        },
        removeWindowScroll () {
            document.removeEventListener("touchmove", this.handleWindowScroll);
        },
        handleWindowScroll (e) {
            e.preventDefault();
        },
        /**
         * 添加绘制线
         */
        draw () {
            this.el = this.$refs.signHandle;
            this.initCanvas();
        },
        /**
         * 初始化canvas配置
         */
        initCanvas () {
            this.el.width = this.canvasWidth;
            this.el.height = this.canvasHeight;
            this.ctx = this.el.getContext("2d");
            this.setCanvas();
        },
        /**
         * canvas配置
         */
        setCanvas () {
            this.ctx.fillStyle = this.canvasBackground;
            //绘制矩形
            this.ctx.fillRect(0, 0, this.canvasWidth, this.canvasHeight);
            //设置线条颜色
            this.ctx.strokeStyle = this.lineColor;
            //设置线条宽度
            this.ctx.lineWidth = this.lineWidth;
            //设置线条两端形状
            this.ctx.lineCap = this.lineRound;
        },
        /**
         * 开始绘制
         */
        drawStart (e) {
            this.addWindowScroll();
            setTimeout(() => {
                this.ctx.beginPath();
                let yIndex = this.getIndex(e.changedTouches[0].clientY);
                this.ctx.moveTo(e.changedTouches[0].clientX - 10, yIndex);
            }, 30);
        },
        /**
         * 绘制过程
         */
        drawing (e) {
            let yIndex = this.getIndex(e.changedTouches[0].clientY);
            this.ctx.lineTo(e.changedTouches[0].clientX - 10, yIndex);
            this.ctx.stroke();
        },
        /**
         * 绘制结束
         */
        drawEnd () {
            this.ctx.closePath();
            setTimeout(() => {
                this.removeWindowScroll();
            }, 30);
        },
        /**
         * 重置
         */
        clearSign () {
            this.initCanvas();
        },
        /**
         * 提交
         */
        saveSign () {
            if (this.loading) {
                return;
            }
            this.loading = true;
            this.$emit('setLoading', this.loading);
            this.$nextTick(() => {
                let $wrap1 = document.getElementById('consent-wrap');

                let options = {
                        scale: 0.5, // 设置缩放比例为原来的一半大小
                        // backgroundColor: 'transparent', // 设置背景颜色为透明
                        };
                html2Canvas($wrap1, options).then(canvas => {
                    const imgBase64 = canvas.toDataURL('image/png');

                    const filename = Date.now();
                    const file = this.dataURLToFile(imgBase64, filename);
                    console.log(file);
                    this.uploadImg(file);
                    // let a = document.createElement('a');
                    // a.download = filename + '.png';
                    // a.href = imgBase64;
                    // a.click();
                }).catch(() => {
                    this.loading = false;
                    this.$emit('setLoading', this.loading);
                    setTimeout(() => {
                        this.$toast.error('生成签名失败');
                    }, 200);
                });
            });
        },
        uploadImg (file) {
            let formData = new FormData();
            formData.append('file', file);
            axios.post('/api/upload/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(resp => {
                let data = resp.data;
                let imgUrl = data.result ? data.result[0] : '';
                this.$emit('setImg', imgUrl);
                this.loading = false;
                this.$emit('setLoading', this.loading);
            }).catch(() => {
                this.loading = false;
                this.$emit('setLoading', this.loading);
                setTimeout(() => {
                    this.$toast.error('上传签名失败');
                }, 200);
            }).finally(() => {
                this.initCanvas();
            });
        },
        dataURLToFile (dataURL, filename) {
            const arr = dataURL.split(',');
            // 获取图片格式
            const imgType = arr[0].match(/:(.*?);/)[1];
            // atob() 方法用于解码使用 base-64 编码的字符串
            const dec = atob(arr[1]);
            let n = dec.length;
            const u8arr = new Uint8Array(n);
            while (n--) {
                // 转成ASCII码
                u8arr[n] = dec.charCodeAt(n);
            }
            return new File([u8arr], filename, { type: imgType });
        },
        getIndex (y) {
            let $wrap1 = document.getElementById('consent-container');
            let $wrap2 = document.getElementById('sign-wrap');
            let h1 = $wrap2.offsetTop - $wrap1.scrollTop;
            return y - h1;
        }
    },
};
</script>
<style lang="scss" scoped>
.sign-content {
    width: 100%;
    border: 1px solid #999;
    position: relative;
    .btn-box {
        height: 44px;
        display: flex;
        justify-content: space-between;
        span {
            display: block;
            width: 50%;
            height: 44px;
            text-align: center;
            line-height: 44px;
            color: #333;
            background: #fff;
        }
    }
}
</style>
