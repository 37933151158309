<template lang='pug'>
#consent-container
    .sign-loading(v-if="loading")
        .toast
            van-loading(size="24px" color="#fff" vertical) 签名上传中
    #consent-wrap
        .title 袋鼠照护服务知情同意书
        p 家长/监护人您好，欢迎加入袋鼠照护服务！
        p 本服务通过袋鼠照护智能管理系统对您孩子进行院内院外、线下线上、全周期、 全方位、连续式管理，此照护团队由医生、营养师、健康照护师等组成。
        //- 第一项
        .sub-title 一、参与本服务，需要您配合做到如下内容：
        p 1. 下载“袋鼠照护”APP， 注册并登陆；
        p 2. 就诊后居家期间， 通过“袋鼠照护”APP 监测并上传孩子个人医疗/护理相关数据等， 为院外管理提供数据支持；
        p 3. 遵医嘱规律来院复诊，并按照要求进行实验室检查、筛查及健康教育。
        //- 第二项
        .sub-title 二、参与本服务， 您能获得以下服务：
        p 1. 个性化的营养及喂养方案
        p 照护团队会根据早产宝宝的年龄和体重等因素，为他们制定个性化的营养计划，提供母乳喂养、配方奶喂养、添加辅食等方面的指导，确保早产宝宝获得足够的营养，促进生长发育。
        p 2. 生长发育监测
        p 通过智能体重秤+APP 软硬件一体化监测宝宝的日常生长发育情况，照护团队会根据宝宝的生长发育情况，实时对喂养方案做出调整，助力他们的体重、身长、头围等指标追赶性生长。
        p 3. 健康动态监测
        p 照护团队会监测评估宝宝日常健康状况并进行相应指导，如常⻅疾病护理指导、疫苗接种指导、⽛⻮健康指导、护眼指导、 就医指导等
        p 4. 家庭护理指导
        p 照护团队会指导家长正确地照顾早产宝宝。包括如何给他们洗澡、换尿布、拍嗝、按摩，以及环境噪音的管理和亲子互动等。
        p 5. 睡眠管理指导
        p 照护团队会指导家长帮助早产宝宝建立良好的睡眠习惯，包括提供舒适的睡眠环境建议等。
        p 6. 照护团队定期线上回访
        p 照护团队定期线上回访家长，评估宝宝在家的生长发育及健康状况，有问题及时发现，及时干预。
        p 7. 照护团队定期上门访视
        p 购买的服务包里，如果包含上门访视服务，照护团队定期会进行上门访视，评估宝宝在家的生长发育及健康状况，指导家长在家喂养及护理指导，进行神经心理行为发育评估及指导，有问题及时发现、及时干预。为宝妈提供乳房护理服务、腹直肌分离评估及康复指导。
        //- 第三项
        .sub-title 三、数据授权：
        p.bold 签署本知情同意书代表您同意将您孩子的数据保存在袋鼠照护系统中，并授权用于以下用途：
        p 1. 医生通过袋鼠照护系统查阅您孩子的个人数据，为您孩子提供喂养方案；
        p 2. 照护团队通过袋鼠照护系统查阅您孩子的个人数据，为您孩子提供健康管理服务；
        p 3. 袋鼠照护管理模式的统计分析和科学研究。
        p.bold 您孩子的个人数据将得到严格保密，如果用于其他用途我们将会再次征得您的同意。
        //- 第四项
        .sub-title 四、服务包相关
        p 1. 服务包每月时长
        ul.default   
            p 服务包中每月时长为30天
        p 2. 服务包计时
        ul.default   
            p 服务包从购买完成的次日开始计时
        .sub-title 五、风险须知
        p.bold 在接受本项目服务之前，请您了解可能存在的风险和不确定性，包括但不限于以下方面：
        p 1. 服务效果不确定性：早产儿的生长发育和健康状况可能受到多种因素的影响，比如早产儿的健康状况、治疗效果、家庭环境等因素等。因此，虽然照护团队会尽最大努力为早产儿提供全面和优质的服务，但是无法保证所有问题都能得到完全解决或者避免出现风险。
        p 2. 照护服务不能代替医院诊疗：早产儿照护团队的服务并不能替代医院的诊疗，因为照护团队的服务主要是为早产儿提供额外的关注、监测和支持，帮助早产儿更好地成长和发育，医生在袋鼠照护平台上给出的咨询建议，也只是根据您的描述而提供的建议性内容，不能作为诊断及医疗的依据。在早产儿出现身体不适或需要治疗时，还需要及时到医院就医并按照医院医生的指示进行治疗。所以，早产儿照护团队的服务是作为早产儿医疗保健的一个重要补充，而不是替代医院的诊疗。
        p 3. 经济压力：本项目需要家长承担一定的费用，这可能会给家庭带来经济压力。请您在做出选择之前，认真考虑自己的经济承受能力。
        p 4. 其他可能的风险：由于早产儿状况的复杂性，还可能存在其他的风险和不确定性，我们将随时与您沟通，解答您的疑虑。
        p 服务提供方：北京爱和未来医疗科技有限公司
        p.bold 在签署此知情同意书之前，我已仔细阅读以上内容，有充分的机会进行询问并得到满意的回答。我已知情并同意以上内容，自愿加入袋鼠照护服务。
        p 家长/监护人签字：
        #sign-wrap
            Sign(:canvasHeight="200", ref="sign", @setImg="setImg", @setLoading="setLoading")
        .date 签字日期：{{ date }}
    .btn-wrap
        .btn.btn1(@click="reset") 重置签名
        .btn.btn2(@click="submit") 确定提交
</template>

<script>
import Sign from './Sign.vue';
import moment from 'moment';
export default {
    components: {
        Sign
    },
    data () {
        return {
            date: '',
            isShow: false,
            loading: false
        };
    },
    mounted () {
        this.date = moment().format('YYYY年MM月DD日');
    },
    methods: {
        reset () {
            this.$refs.sign.clearSign();
        },
        submit () {
            this.$refs.sign.saveSign();
        },
        setImg (url) {
            this.$emit('setImg', url);
        },
        setLoading (loading) {
            this.loading = loading;
        },
        init () {
            this.$refs.sign.initCanvas();
        }
    }
};
</script>

<style lang='sass' scoped>
.sign-loading 
    position: fixed
    width: 100%
    height: 100%
    left: 0
    top: 0
    z-index: 9999
    display: flex
    justify-content: center
    align-items: center
    .toast 
        height: 120px
        width: 120px
        background: rgba(0, 0, 0, 0.75)
        display: flex
        justify-content: center
        align-items: center
        border-radius: 8px
        color: #fff
#consent-container
    width: 100%
    height: 100%
    overflow: auto
    background: #fff
    padding: 14px 0 14px 0 
    #consent-wrap
        padding-bottom: .3rem
    .btn-wrap
        display: flex
        justify-content: flex-end
        padding: 0 10px
        margin-top: -1rem
        .btn
            height: .64rem
            line-height: .64rem
            width: fit-content
            background: linear-gradient(90deg, #29E6DA 0%, #0DC2CB 100%)
            border-radius: .32rem
            padding: 0 .2rem
            font-size: 13px
            color: #fff
        .btn1
            background: linear-gradient(134deg, #FEC56A 0%, #FF9F60 100%)
            margin-right: .2rem
    .title
        font-size: 18px
        text-align: center
        font-weight: bold
        margin-bottom: 10px
    p,.date
        padding: 0 10px
        line-height: 30px
        font-size: 14px
    p.bold 
        font-weight: bold
    ul.default
        list-style: disc
        padding-left: 40px
        line-height: 30px
        font-size: 14px
    .sub-title
        padding: 0 10px
        font-weight: bold
        line-height: 30px
        font-size: 14px
    .table-wrap
        padding: 10px
        table
            width: 100%
            border: 1px solid #333
            border-collapse: collapse
            th
                border: 1px solid #333
                background: #B0B3B2
                font-size: 14px
                font-weight: normal
                padding: 6px
            td
                border: 1px solid #333
                font-size: 14px
                padding: 6px
    .date
        line-height: .8rem
        font-size: 13px
        color: #aaa
    #sign-wrap
        padding: 0 10px
        margin-bottom: .2rem
</style>
    