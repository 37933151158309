<template lang='pug'>
.pay2-introduce-page
    img(src="@/assets/pay2/introduce.jpeg")
</template>

<script>
export default {
    data () {
        return {
        };
    },
    methods: {
    }
};
</script>

<style lang='sass' scoped>
.pay2-introduce-page
    img
        width: 100%
</style>
    