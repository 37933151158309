const state = {
    isShow: false
};

const mutations = {
    'global/setIsShow' (state, boolean) {
        state.isShow = boolean;
    }
};

export default {
    state,
    mutations
};
