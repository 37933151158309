<template lang='pug'>
.calc-page
    van-popup(
        v-model="show"
        position="bottom"
    )
        van-datetime-picker(
            v-model="currentDate"
            type="date"
            title="选择年月日"
            :min-date="minDate"
            :max-date="maxDate"
            @confirm="handleConfirm"
            @cancel="handleCancel"
        )
    .picker-wrap
        .picker-box 
            .label 出院日期：
            .value(@click="showPicker") {{ date }}
    .line
    .res-box 
        p 出院日期：{{ date }}
        p 当前日期：{{ date2 }}
        p 计费日期：{{ date3 }}
        p 扣除天数：{{ num }}
        p.title 三月包
        p 高危：{{ form.money1 | formatNum }}元，低危：{{ form.money2 | formatNum }}元
        p.title 半年包
        p 高危：{{ form.money3 | formatNum }}元，低危：{{ form.money4 | formatNum }}元
        p.title 一年包
        p 高危：{{ form.money5 | formatNum }}元，低危：{{ form.money6 | formatNum }}元
</template>

<script>
import moment from 'moment';
export default {
    data () {
        return {
            show: false,
            minDate: new Date(2020, 0, 1),
            maxDate: new Date(),
            currentDate: new Date(),
            date: '',
            date2: '',
            date3: '',
            num: '',
            form: {
                money1: 0,
                money2: 0,
                money3: 0,
                money4: 0,
                money5: 0,
                money6: 0
            },
            high: [2400, 1000, 800, 800, 600, 600, 600, 400, 400, 400, 400, 400],
            low: [1800, 800, 600, 600, 500, 500, 500, 300, 300, 300, 300, 300]
        };
    },
    filters: {
        formatNum (val) {
            if (val == '-' || val == '') {
                return val;
            }
            if (parseInt(val) === parseFloat(val)) {
                return val;
            } else {
                return val.toFixed(2);
            }
        }
    },
    methods: {
        showPicker () {
            this.show = true;
        },
        handleConfirm (e) {
            this.date = moment(e).format('YYYY-MM-DD');
            this.date2 = moment().format('YYYY-MM-DD');
            this.date3 = moment().add(1, 'days').format('YYYY-MM-DD');
            this.num = moment(this.date2).diff(moment(this.date), 'days');
            this.show = false;
            this.initData();
        },
        handleCancel () {
            this.show = false;
        },
        getArrTotal (arr, start, end) {
            let newArr = arr.slice(start, end);
            let total = 0;
            if (newArr.length) {
                newArr.forEach(x => {
                    total += x;
                });
            }
            return total;
        },
        initData () {
            if (this.num >= 360 || this.num < 0) {
                this.form.money1 = '-';
                this.form.money2 = '-';
                this.form.money3 = '-';
                this.form.money4 = '-';
                this.form.money5 = '-';
                this.form.money6 = '-';
                return;
            }
            let val1 = parseInt(this.num / 30);
            let val2 = 30 - this.num % 30;
            if (val1 <= 2) {
                this.form.money1 = (this.high[val1] / 30 * val2) + this.getArrTotal(this.high, val1 + 1, 3);
                this.form.money2 = (this.low[val1] / 30 * val2) + this.getArrTotal(this.low, val1 + 1, 3);
            } else {
                this.form.money1 = '-';
                this.form.money2 = '-';
            }
            if (val1 <= 5) {
                this.form.money3 = (this.high[val1] / 30 * val2) + this.getArrTotal(this.high, val1 + 1, 6);
                this.form.money4 = (this.low[val1] / 30 * val2) + this.getArrTotal(this.low, val1 + 1, 6);
            } else {
                this.form.money3 = '-';
                this.form.money4 = '-';
            }
            this.form.money5 = (this.high[val1] / 30 * val2) + this.getArrTotal(this.high, val1 + 1, 12);
            this.form.money6 = (this.low[val1] / 30 * val2) + this.getArrTotal(this.low, val1 + 1, 12);
        }
    }
};
</script>

<style lang='sass' scoped>
.calc-page
    .picker-wrap
        padding: 20px
    .picker-box 
        height: 36px
        line-height: 36px
        display: flex
        .label
        .value
            flex: 1
            line-height: 36px
            border: 1px solid #ddd
            border-radius: 6px
            padding: 0 10px
            color: #333
    .line
        width: 100%
        height: 1px 
        background: #ddd
    .res-box
        padding: 20px
        p
            line-height: 28px
            font-size: 14px
        p.title
            font-weight: bold

</style>
    