<template lang='pug'>
.appraisal-result-list
    van-list(
        v-model="loading",
        :finished="finished",
        finished-text="没有更多了",
        @load="onLoad"
    )
        .list-wrap
            .result-item(@click="goToDetail(item)" v-for="item in list" :key="item.id")
                .left
                    van-image(
                        round
                        width="1rem"
                        height="1rem"
                        fit="cover"
                        :src="item.avatar"
                    )
                .right
                    .w1 {{ item.title }}
                    .w2 测评时间：{{ item.created_at }}
</template>

<script>
import axios from 'axios';
import moment from 'moment';
export default {
    data () {
        return {
            channel: 1004,
            loading: false,
            finished: false,
            page: 1,
            list: [],
            type: 1
        };
    },
    computed: {
        token () {
            return this.$route.query.token;
        },
        userId () {
            return this.$route.query.cp_user_id;
        }
    },
    methods: {
        // getBabyData () {
        //     axios.post('/api/web/Evaluation/babyDetails', {
        //         user_id: this.userId
        //     }, {
        //         headers: {
        //             Authorization: 'bearer ' + this.token
        //         }
        //     }).then((resp) => {
        //         let data = resp.data;
        //         let days = moment().diff(moment(data.result.birthday), 'days');
        //         if (days > 365 * 3) {
        //             this.type = 2;
        //         } else {
        //             this.type = 1;
        //         }
        //     });
        // },
        onLoad () {
            if (!this.token) {
                this.finished = true;
                this.loading = false;
                this.$toast('缺少必要参数');
                return;
            } else {
                localStorage.setItem('ceping_token', this.token);
            }
            axios.post('/api/web/Evaluation/create', {
                id: this.userId,
                page: this.page
            }, {
                headers: {
                    Authorization: 'bearer ' + this.token,
                    channel: this.channel
                }
            }).then((resp) => {
                let data = resp.data;
                if (!data.success) {
                    this.$toast(data.errors.message);
                }
                this.list = this.list.concat(data.result);
                this.loading = false;
                if (data.result.length < 10) {
                    this.finished = true;
                }
            });
        },
        goToDetail (item) {
            let days = moment().diff(moment(item.daishu_birth_date), 'days');
            if (days > 365 * 3) {
                this.$router.push('/appraisal/result2?test_id=' + item.id + '&is_back=1');
            } else {
                this.$router.push('/appraisal/result1?test_id=' + item.id + '&is_back=1');
            }
        }
    }
};
</script>

<style lang='sass' scoped>
.appraisal-result-list
    height: 100%
    width: 100%
    overflow: auto
    background: #F4F8FF
    padding: .4rem
    color: #51607D
    .result-item
        background: #FFFFFF
        box-shadow: 0px 0px 38px 0px rgba(59,67,73,0.09)
        border-radius: .3rem
        margin-bottom: .4rem
        padding: .4rem
        display: flex
        align-items: center
        .left
            height: 1rem
            width: 1rem
            margin-right: .2rem
        .right
            .w1
                color: #2F3E63
                font-size: 16px
                margin-bottom: .1rem
            .w2
                color: #9CA5B6
                font-size: 13px
    .result-item:last-child
        margin-bottom: 0
</style>