<template lang='pug'>
.appraisal-check-page
    base-info(ref="info" v-if="step == 0", @confirm="startCheck")
    .wrap(v-else)
        .check-jingxi(
            v-if="step > 0"
            :class="{'check-cuda': current.test_sub_type_id == 1}"
        )
            .title 
                .inner-title {{ current.test_sub_type_text }}
            .content(v-if="current.growth")
                .content-top
                    .name
                        .num {{ step < 10 ? '0' + step : '' + step }}
                        .word {{ current.growth.name }}
                    .list
                        .item · {{ current.growth.capability_name }}
                        .item · {{ current.growth.test_tool_name }}
                        .item · {{ current.growth.guide }}
                    .tips
                        .w1 观察
                        .w2 {{ current.growth.viewpoint }}
                .content-middle 
                    .check-list
                        .check-item(
                            v-for="item in current.optionList",
                            :class="{'check': currentResult == item.value}"
                            @click="setItem(item)"
                        ) {{ item.value }} {{ item.name }}
                .content-bottom
                    .left.disabled
                        van-icon(name="arrow-left" size="24px")
                    .right(@click="nextItem" :class="{'disabled': loading}")
                        van-icon(name="arrow" size="24px")
</template> 

<script>
import BaseInfo from './Info.vue';
import axios from 'axios';
import _ from 'lodash';
export default {
    components: {
        BaseInfo
    },
    data () {
        return {
            channel: 1004,
            isOver: false,
            loading: false,
            testId: 0, // 测评id
            step: 0, // 第几步
            current: {}, // 当前题目信息  
            currentResult: '' // 结果选项
        };
    },
    computed: {
        token () {
            return localStorage.getItem('ceping_token');
        },
        userId () {
            return this.$route.query.cp_user_id;
        },
        continueTestId () {
            return this.$route.query.continue_test_id;
        }
    },
    mounted () {
        if (this.continueTestId) {
            this.step = 1;
            this.testId = this.continueTestId;
            this.current = {};
            this.currentResult = '';
            let res = _.cloneDeep(this.$store.state.appraisal.question);
            let arr = [
                {
                    name: res.growth.max_option,
                    value: res.growth.max_show
                },
                {
                    name: res.growth.middle_option,
                    value: res.growth.middle_show
                },
                {
                    name: res.growth.min_option,
                    value: res.growth.min_show
                }
            ];
            res.optionList = arr;
            this.current = res;
        } else {
            this.step = 0;
            this.current = {};
        }
    },
    methods: {
        startCheck (obj) {
            axios.post('/api/web/Evaluation/growthTestCreate', {
                children_id: this.userId,
                height: obj.height,
                weight: obj.weight
            }, {
                headers: {
                    Authorization: 'bearer ' + this.token,
                    channel: this.channel
                }
            }).then((resp) => {
                let data = resp.data;
                if (!data.success) {
                    this.$toast(data.errors.message);
                }
                if (data.result && data.result.children_test_id) {
                    this.testId = data.result.children_test_id;
                    this.getQuestion(() => {
                        this.$refs.info.loading = false;
                    });
                }
            });
        },
        getQuestion (cb) {
            if (this.loading) {
                return;
            }
            let params = {
                children_test_id: this.testId
            };
            if (this.current.id && this.step) {
                params.current_growth_id = this.current.id;
                params.current_check_option = this.currentResult;
            } else {
                params.current_growth_id = 0;
                params.current_check_option = 'None';
            }
            console.log(params);
            this.loading = true;
            axios.post('/api/web/Evaluation/growthTestGetItem', params, {
                headers: {
                    Authorization: 'bearer ' + this.token,
                    channel: this.channel
                }
            }).then((resp) => {
                let data = resp.data;
                if (!data.success) {
                    this.$toast(data.errors.message);
                    return;
                }
                if (data.success && (!data.result || !data.result.id)) {
                    this.isOver = true;
                    this.$router.push('/appraisal/result1?test_id=' + this.testId);
                    return;
                }
                this.currentResult = ''; // 置空答案
                cb && cb(); // 取消loading
                this.step += 1; // 步骤+1
                let arr = [
                    {
                        name: data.result.growth.max_option,
                        value: data.result.growth.max_show
                    },
                    {
                        name: data.result.growth.middle_option,
                        value: data.result.growth.middle_show
                    },
                    {
                        name: data.result.growth.min_option,
                        value: data.result.growth.min_show
                    }
                ];
                data.result.optionList = arr;
                this.current = data.result;
            }).finally(() => {
                this.loading = false;
            });
        },
        setItem (obj) {
            this.currentResult = obj.value;
        },
        nextItem () {
            if (this.isOver) {
                return;
            }
            if (this.currentResult) {
                this.getQuestion();
            } else {
                this.$toast('请选择答案');
            }
        }
    }
};
</script>

<style lang='sass' scoped>
.appraisal-check-page
    height: 100%
    width: 100%
    overflow: auto
    background: #F4F8FF
    color: #51607D
    padding: .4rem
    .wrap
    .check-jingxi
        .title
            line-height: .8rem
            padding: 0 1rem
            .inner-title
                background: linear-gradient(105deg, #A0E3C9, #62C698)
                line-height: .8rem
                border-top-left-radius: .8rem
                border-top-right-radius: .8rem
                color: #fff
                text-align: center
                font-weight: bold
        .content
            background: #fff
            box-shadow: 0px 0px 38px 0px rgba(59,67,73,0.09)
            border-radius: .3rem
            overflow: hidden
            .content-top
                background: linear-gradient(175deg, #EBFFF6, #FFFFFF)
                padding: .4rem
                .name
                    display: flex
                    align-items: center
                    margin-bottom: .2rem
                    .num
                        height: .64rem
                        width: .64rem
                        line-height: .58rem 
                        text-align: center
                        border-radius: 50%
                        border: 3px solid #61C296
                        color: #61C296
                        font-size: 15px
                        font-weight: bold
                        margin-right: .2rem
                    .word
                        color: #61C296
                        font-weight: bold
                .list
                    margin-bottom: .2rem
                    .item
                        font-size: 14px
                        line-height: .48rem
                .tips
                    display: flex 
                    align-items: center
                    .w1
                        background: #61C296
                        font-size: 13px
                        line-height: .4rem
                        height: .4rem
                        padding: 0 .2rem
                        border-radius: .2rem
                        color: #fff
                        margin-right: .16rem
                    .w2
                        color: #61C296
                        font-weight: bold
            .content-middle 
                padding: .4rem
                .check-list
                    .check-item
                        padding: .2rem
                        font-size: 14px
                        margin-bottom: .4rem
                        background: #F4F8FF
                        border-radius: .2rem
                    .check
                        background: #61C296
                        color: #fff
                    .check-item:last-child
                        margin-bottom: 0
            .content-bottom
                display: flex
                padding: .4rem
                margin-bottom: .4rem
                justify-content: space-between
                .left,
                .right
                    width: 1rem
                    height: 1rem
                    border-radius: 50%
                    display: flex 
                    justify-content: center
                    align-items: center
                    background: #588CF6
                    color: #fff
                .left.disabled
                    background: #F4F8FF
                    color: #ADB7CD
                .right.disabled
                    opacity: 0.5
    .check-cuda 
        .inner-title
            background: linear-gradient(105deg, #AAC6FF, #588CF6) !important
        .content-top     
            background: linear-gradient(175deg, #DFEAFF, #FFFFFF) !important
        .name
            .num 
                border: 3px solid #588CF6 !important
                color: #588CF6 !important
            .word 
                color: #588CF6 !important
        .tips
            .w1
                background: #588CF6 !important
            .w2
                color: #588CF6 !important
        .check
            background: #588CF6 !important
</style>
        