import Vue from "vue";
import Vuex from "vuex";
// 全局
import global from "./modules/global";
import appraisal from "./modules/appraisal";

const DEBUG = process.env.NODE_ENV !== "production";
Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        global,
        appraisal
    },
    strict: false,
    plugins: []
});

export default store;
