(function (doc, win) {
    const uiWidth = 750;
    const docEl = doc.documentElement;
    const resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize';
    const recalc = function () {
        let clientWidth = docEl.clientWidth;
        if (!clientWidth) {
            return;
        }
        if (clientWidth >= uiWidth) {
            clientWidth = uiWidth;
        }
        docEl.style.fontSize = 100 * (clientWidth / uiWidth) + 'px';
    };
    if (!doc.addEventListener) {
        return;
    }
    win.addEventListener(resizeEvt, recalc, false);
    doc.addEventListener('DOMContentLoaded', recalc, false);
    recalc();
    doc.body.addEventListener('focusin', (event) => {
        var ua = navigator.userAgent;
        var isAndroid = /android/i.test(ua); //android终端
        if (isAndroid) {
            const tagName = event.target.tagName.toUpperCase();
            if (tagName === 'INPUT' || tagName === 'TEXTAREA') {
                event.target.scrollIntoViewIfNeeded();
            }
        }
    });
})(document, window);
