<template lang='pug'>
.appraisal-video-list
    van-skeleton(
        title,
        :loading="loading"
        :row="10"
    )
        .top-title
            .back(@click="goBack") 返回
            .w1 全部视频
        .list 
            .item(v-for="item in list" :key="item.id" @click="goToDetail(item)")
                .left
                    img(:src="item.avatar")
                .right
                    .right-name {{ item.title }}
                    .right-tip {{ item.physiology_aim }}
</template>

<script>
import axios from 'axios';
export default {
    data () {
        return {
            channel: 1004,
            list: [],
            loading: false
        };
    },
    computed: {
        token () {
            return localStorage.getItem('ceping_token');
        },
        testId () {
            return this.$route.query.test_id;
        }
    },
    mounted () {
        if (this.testId) {
            this.getData();
        }
    },
    methods: {
        getData () {
            this.loading = true;
            axios.post('/api/web/Evaluation/videos', {
                id: this.testId
            }, {
                headers: {
                    Authorization: 'bearer ' + this.token,
                    channel: this.channel
                }
            }).then((resp) => {
                let data = resp.data;
                if (!data.success) {
                    this.$toast(data.errors.message);
                }
                this.list = data.result;
            }).finally(() => {
                this.loading = false;
            });
        },
        goToDetail (item) {
            this.$router.push('/appraisal/video-detail?id=' + item.id);
        },
        goBack () {
            this.$router.back();
        }
    }
};
</script>

<style lang='sass' scoped>
.appraisal-video-list
    height: 100%
    width: 100%
    overflow: auto
    background: #F4F8FF
    padding: .4rem
    color: #51607D
    .top-title
        margin-bottom: .4rem
        text-align: center
        position: relative
        .back
            position: absolute
            left: 0 
            top: 0
            height: 100%
            font-size: 14px
            color: #5B8EF7
            padding-top: 1px
        .w1
            font-size: 16px
            font-weight: bold
    .list
        .item
            background: #FFFFFF
            box-shadow: 0px 0px 38px 0px rgba(59,67,73,0.09)
            border-radius: .3rem
            margin-bottom: .4rem
            display: flex
            padding: .4rem
            .left
                width: 3rem
                border-radius: .2rem
                overflow: hidden
                margin-right: .4rem
                img
                    width: 3rem
            .right
                flex: 1
                display: flex
                flex-direction: column
                justify-content: center
                .right-name
                    font-size: 14px
                    margin-bottom: .2rem
                    font-weight: bold
                .right-tip
                    font-size: 12px
                    color: #9CA5B6
        .item:last-child
            margin-bottom: 0
</style>