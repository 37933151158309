<template lang='pug'>
.pay2-page
    //- 明细
    van-popup(v-model="show1" position="bottom" closeable)
        .detail-wrap
            .item 
                .label 购买时长：
                .value {{ current && current.duration }}天
            .item 
                .label 费用详情：
                .value 
                    table.detail-table
                        tr
                            th 项目
                            th 单价
                            th 数量
                            th 总价
                        tr(v-for="item in currentDetail")
                            td {{ item.project_name }}
                            td {{ item.single_price }}
                            td {{ item.number }}
                            td {{ item.total_price }}
            .item 
                .label 合计：
                .value ¥{{ current && current.scribe_money }}
            //- .item 
            //-     .label 首月优惠：
            //-     .value ¥{{ current && current.money }}
            .item 
                .label.bold 应付：
                .value.bold ¥{{ current && current.money }}
    //- 权益
    van-popup(v-model="show2" closeable)
        .power2-wrap
            .title VIP付费包权益介绍
            .list
                .item(v-for="item in power2List" :key="item.name")
                    .name {{ item.name }}
                    .value {{ item.value }}
    //- 支付
    van-popup(v-model="show3" position="bottom" :close-on-click-overlay="false")
        .pay-wrap
            .title 需支付 ¥{{ money }}
            .list
                .item(v-for="item in payList" :key="item.name" @click="submitPay(item)")
                    .left
                        img(:src="getImg2(item)")
                        .name {{ item.name }}
                    .right
                        img(src="@/assets/pay2/arrow-right.png")
            .bottom
                .btn(@click="cancelPay") 取消支付
    //- 签名
    van-popup(v-model="show4" position="right" closeable style="width: 100%; height: 100%;")
        .sign-wrap
            Consent(@setImg="showPay" ref="consent")
    //- footer
    .footer(v-show="current.package_id")
        .button 优惠价
        .top
            .top-wrap
                //- .name(@click="showDetail") 明细
                .money 原价¥ {{ originMoney }}
            .btn(@click="showPower") 袋鼠照护VIP付费包权益
        .bottom(v-if="isSign == 0" @click="showConsent") ¥{{ money }} 签署知情同意书并支付
        .bottom(v-else @click="showConsent2")  ¥{{ money }} 立即支付
    .inner-wrap
        .profile
            .status(:class="getClass(status)") {{ status | filterStatus}}
            .top-title
                .title 袋鼠照护VIP
            .middle-name {{ name }}
            .bottom-date(v-if="date") 会员将在{{ date }}到期
        .power
            .title
                .left
                    img(src="@/assets/pay2/name-left.png")
                span 会员专享5大权益
                .right
                    img(src="@/assets/pay2/name-right.png")
            .list 
                .item(v-for="(item, index) in powerList" :key="item")
                    .img-box
                        img(:src="getImg(index + 2)")
                    .name {{ item }}
        .goods
            .title 选择套餐
            .list
                .item(
                    v-for="item in goodList" 
                    :key="item.package_id" 
                    :class="{'active': item.package_id == current.package_id}"
                    @click="checkItem(item)"
                )
                    .name {{ item.package_name }}
                    .sub {{ item.duration }}天
</template>

<script>
import Consent from '@/components/Consent.vue';
import axios from 'axios';
import moment from 'moment';
import { TOKEN } from '@/utils/config';
export default {
    components: {
        Consent
    },
    data () {
        return {
            show1: false,
            show2: false,
            show3: false,
            show4: false,
            status: -1,
            name: '',
            date: '',
            money: 0,
            originMoney: 0,
            current: {},
            currentDetail: [],
            signImgUrl: '',
            isSign: 0,
            powerList: [],
            goodList: [],
            power2List: [],
            payList: [],
            loading: false,
            token: ''
        };
    },
    filters: {
        filterStatus (val) {
            let obj = {
                0: '未开通',
                1: '服务中',
                2: '已过期',
                3: '体验中'
            };
            return obj[val];
        }
    },
    computed: {
        // token () {
        //     return this.$route.query.token;
        // },
        callback () {
            return this.$route.query.callback;
        }
    },
    beforeMount () {
        this.powerList = [
            // '7×24小时\n极速响应',
            // '专业团队\n视频指导',
            '疑难问题\n贴心上门',
            '个性化\n喂养方案',
            '生长发育\n监测指导',
            '全程健康\n监测指导',
            '线下就诊\nVIP体验'
        ];
        this.payList = [
            {
                name: '微信支付',
                icon: 'weixin.png'
            },
            {
                name: '支付宝',
                icon: 'zhifubao.png'
            }
        ];
        this.power2List = [
            // {
            //     name: '7×24小时极速响应',
            //     value: '专属医⽣+专属照护团队全天候提供线上咨询'
            // },
            {
                name: '个性化喂养及照护⽅案',
                value: '专属医⽣+专属照护团队制定个性化⽅案，实时指导，定期线上回访，VIP体验'
            },
            {
                name: '贴心上门指导',
                value: '资深护士上门提供专业的早产儿护理指导，宝宝健康，妈妈省心'
            },
            {
                name: '全程⽣⻓发育监测指导',
                value: '体格发育监测、神经发育监测，对可能出现发育迟缓的⼊组患⼉，早发现早⼲预早治疗'
            },
            {
                name: '全程健康监测指导',
                value: '常⻅疾病护理指导、疫苗接种指导、⽛⻮健康、护眼指导、就医指导'
            },
            {
                name: '线下就诊VIP体验',
                value: '诊前接待、医嘱解释、指导预约检查、检查导诊等'
            }
        ];
    },
    mounted () {
        //==== 过审专用
        let type = this.$route.query.type;
        if (type == 'test') {
            this.token = TOKEN;
        } else {
            let token = this.$route.query.token;
            this.token = token;
        }
        // ====
        if (this.token) {
            this.getData();
        } else {
            this.$toast.fail('缺少令牌');
        }
        if (this.callback == 1) {
            this.showConfirm();
        }
    },
    methods: {
        getData () {
            axios.get('/api/pay/vip_renew', {
                headers: {
                    Authorization: 'bearer ' + this.token,
                    channel: 1000
                }
            }).then((res) => {
                console.log(res);
                let data = res.data;
                if (data.success) {
                    this.status = data.result.is_member;
                    this.name = data.result.hospital_name + '-' + data.result.feature;
                    if (data.result.vip_expire_time) {
                        this.date = moment(data.result.vip_expire_time * 1000).format('YYYY.MM.DD');
                    }
                    this.goodList = data.result.servicePackageInfo;
                } else {
                    this.$toast(data.errors.message);
                }
            });
        },
        getDetail () {
            axios.get('/api/pay/vip_package_detail', {
                headers: {
                    Authorization: 'bearer ' + this.token,
                    channel: 1000
                },
                params: {
                    package_id: this.current.package_id
                }
            }).then((res) => {
                let data = res.data;
                console.log(data);
                this.currentDetail = data.result || [];
            });
        },
        getClass (status) {
            if (status == 3) {
                return 'yellow';
            } else if (status == 1) {
                return 'orange';
            } else if (status == 2 || status == 0) {
                return 'gray';
            }
        },
        getImg (index) {
            return require(`@/assets/pay2/icon-${index + 1}.png`);
        },
        getImg2 (item) {
            return require(`@/assets/pay2/${item.icon}`);
        },
        checkItem (item) {
            this.current = item;
            this.money = item.money;
            this.originMoney = item.scribe_money;
        },
        showDetail () {
            this.show1 = true;
            this.getDetail();
        },
        showPower () {
            this.show2 = true;
        },
        showConsent () {
            this.show4 = true;
            this.$refs.consent && this.$refs.consent.init();
            let $wrap1 = document.getElementById('consent-container');
            setTimeout(() => {
                $wrap1 && $wrap1.scrollTo(0, 0);
            }, 100);
        },
        showConsent2 () {
            this.showPay('');
        },
        showPay (url) {
            this.signImgUrl = url;
            this.show4 = false;
            setTimeout(() => {
                this.show3 = true;
            }, 300);
        },
        cancelPay () {
            this.show3 = false;
            this.signImgUrl = '';
        },
        submitPay (item) {
            if (this.loading) {
                this.$toast('申请支付中，请勿重复点击');
                return;
            }
            if (!this.token) {
                this.$toast.fail('缺少令牌');
                return;
            }
            this.loading = true;
            let type = 1;
            if (item.name == '支付宝') {
                type = 2;
            }
            let obj = {
                money: this.money,
                payment_method_type: type,
                package_id: this.current.package_id
            };
            if (this.isSign == 0) {
                obj.consent_agreement_signature_photo = this.signImgUrl;
            }
            if (type == 1) {
                let url = `pay://wxpay-vip?money=${obj.money}&payment_method_type=${obj.payment_method_type}&package_id=${obj.package_id}&consent_agreement_signature_photo=${encodeURIComponent(obj.consent_agreement_signature_photo)}`;
                window.location.href = url;
            } else {
                let url = `pay://alipay-vip?money=${obj.money}&payment_method_type=${obj.payment_method_type}&package_id=${obj.package_id}&consent_agreement_signature_photo=${encodeURIComponent(obj.consent_agreement_signature_photo)}`;
                window.location.href = url;
            }
            this.loading = false;
            // axios.post('/api/pay/vip_generate_order', obj, {
            //     headers: {
            //         Authorization: 'bearer ' + this.token,
            //         channel: 1000
            //     }
            // }).then(resp => {
            //     let data = resp.data;
            //     if (data.success) {
            //         // 跳转地址
            //         window.location.href = data.result;
            //     } else {
            //         this.$toast.fail(data.errors.message);
            //     }
            // }).catch(() => {
            //     this.$toast.fail('申请支付失败');
            // }).finally(() => {
            //     this.show3 = false;
            //     setTimeout(() => {
            //         this.loading = false;
            //     }, 1000);
            // });
        },
        showConfirm () {
            this.$dialog.confirm({
                title: '支付确认',
                message: '如果您已支付成功，请点击“已完成支付”按钮;如果放弃支付，请选择取消。',
                showCancelButton: true,
                confirmButtonText: '已完成支付',
                confirmButtonColor: '#05C2CB'
            }).then(() => {
                // this.getData();
                this.$router.push({
                    path: '/pay-over',
                    query: {
                        token: this.token
                    }
                });
            }).catch(() => {
                console.log('已取消');
            });
        }
    }
};
</script>

<style lang='sass' scoped>
.detail-table
    width: 100%
    border: 1px solid #999
    td, th
        padding: 4px
        text-align: center
    tr, th, td
        border: 1px solid #999
.detail-wrap
    padding: .64rem .32rem
    .item
        display: flex
        // align-items: center
        font-size: 13px
        color: rgba(0, 0, 0, 0.55)
        margin-bottom: .2rem
        .bold
            font-weight: bold
            color: #000
        .label
            width: 1.8rem
            line-height: .4rem
        .value 
            flex: 1
            line-height: .4rem
    .item:last-child
        margin-bottom: 0
.power2-wrap
    width: 6rem
    padding: .64rem .32rem
    .title
        font-size: 16px
        font-weight: bold
        text-align: center
        margin-bottom: .4rem
    .list
        border-top: 1px solid #eee
        .item
            padding: .4rem 0
            border-bottom: 1px solid #eee
            .name
                text-align: center 
                font-size: 14px 
                font-weight: bold
                margin-bottom: .24rem
            .value
                font-size: 13px
.pay-wrap 
    padding: .4rem
    .title
        font-size: 16px
        font-weight: bold
        text-align: center
        margin-bottom: .4rem
    .list
        .item
            height: 1.44rem
            border-radius: 10px
            border: 1px solid #EEEEEE
            margin-bottom: .24rem
            display: flex
            justify-content: space-between
            align-items: center
            padding: 0 .4rem
            .left
                display: flex
                align-items: center
                font-size: 14px
                img
                    width: .56rem
                    margin-right: .2rem
            .right
                img
                    width: .14rem
    .bottom
        padding: .48rem
        text-align: center
        .btn
            font-size: 18px
.sign-wrap
    width: 100%
    height: 100%
.gray 
    background: #C6C6C6
.yellow
    background: #FFBB3A
.orange
    background: #FF9951
.pay2-page
    width: 100%
    height: 100%
    .footer
        position: fixed
        bottom: 0
        left: 0
        width: 100%
        height: 3rem
        background: #fff
        box-shadow: 0px 6px 10px 4px rgba(0,0,0,0.2)
        padding: .4rem .32rem 0 .32rem
        .button
            position: absolute
            left: .32rem
            top: 1rem
            height: .4rem
            font-size: 12px
            color: #fff
            line-height: .38rem
            padding: 0 .12rem
            background: #FD7963
            border-radius: .2rem
        .top
            display: flex
            justify-content: space-between
            align-items: center
            font-size: 13px
            margin-bottom: .4rem
            .top-wrap
                display: flex
                align-items: center
            .money
                color: #888
                text-decoration: line-through
            .name
                color: #FFBF6B
                margin-right: 10px
            .btn
                color: #05C2CB
        .bottom 
            height: 1rem
            background: linear-gradient(134deg, #FEC56A 0%, #FF9F60 100%)
            box-shadow: 0px 2px 4px 0px rgba(255,159,96,0.4)
            border-radius: 4px
            text-align: center
            line-height: 1rem
            color: #fff 
            font-size: 17px
            font-weight: 300
    .inner-wrap
        width: 100%
        background-image: url(../../assets/pay2/bg.png)
        background-size: 100% 60%
        background-repeat: no-repeat
        padding: .32rem
        padding-bottom: 3.3rem
        .profile
            position: relative
            height: 2.7rem
            border-radius: .24rem
            background-image: url(../../assets/pay2/bg2.png)
            background-size: 100% 100%
            box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.1)
            overflow: hidden
            padding: .52rem .4rem 0 .4rem
            margin-bottom: .4rem
            .status
                position: absolute
                width: 1.22rem
                height: .56rem
                border-bottom-left-radius: .24rem
                border-top-right-radius: .24rem
                right: 0 
                top: 0
                font-size: 12px
                color: #fff
                text-align: center
                line-height: .56rem
            .top-title
                margin-bottom: .16rem
                .title
                    width: fit-content
                    line-height: .4rem
                    height: .4rem
                    font-size: 12px
                    padding: 0 .2rem
                    background: #FFCC6C
                    border-radius: .2rem
                    font-weight: bold
            .middle-name
                font-size: 18px
                margin-bottom: .16rem
                font-weight: bold
            .bottom-date
                font-size: 12px
                color: #444
                font-weight: 400
        .power
            height: 5.8rem
            background: #FFFFFF
            box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.1)
            border-radius: .24rem
            padding: .48rem 0 0 .3rem
            margin-bottom: .4rem
            .title
                display: flex
                justify-content: center
                align-items: center
                img
                    width: .64rem
                span
                    font-size: 16px 
                    font-weight: bold
                    margin: 0 .12rem
            .list
                display: flex
                flex-wrap: wrap
                white-space: pre-line
                .item
                    .img-box
                        width: 1.56rem
                        height: 1.42rem
                        padding-top: .4rem
                        display: flex
                        justify-content: center 
                        img
                            width: .8rem
                            height: .8rem
                    .name
                        font-size: 12px
                        color: rgba(0, 0, 0, 0.55)
                        text-align: center
        .goods
            .title
                font-size: 16px
                font-weight: bold
                margin-bottom: .24rem
            .list
                display: flex 
                flex-wrap: wrap
                justify-content: space-between
                .item 
                    width: 2.1rem
                    height: 2.1rem
                    background: #FFFFFF
                    border-radius: 16px
                    border: 3px solid rgba(5,194,203,0.1)
                    margin-bottom: .2rem
                    display: flex 
                    flex-direction: column
                    justify-content: center 
                    align-items: center
                    .name
                        font-size: 12px 
                        color: rgba(0, 0, 0, 0.55)
                        margin-bottom: .12rem
                    .sub
                        font-size: 22px
                .item.active
                    background: #E6F8F9
                    border: 3px solid #04C1CA
</style>
        