<template lang='pug'>
#app
    router-view 
</template>

<script>
export default {
    data () {
        return {

        };
    }
};
</script>

<style lang='sass'>
body, html
    height: 100%
    width: 100%
    background: #ffffff
#app 
    width: 100%
    height: 100%
    overflow: auto
</style>
