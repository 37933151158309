import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

import Complain from '@/views/Complain.vue';
import Pay from '@/views/Pay.vue';
import PayRights from '@/views/PayRights.vue';
import PayOver from '@/views/PayOver.vue';
import Calculate from '@/views/Calculate.vue';
import Pay2 from '@/views/pay2/Pay.vue';
import Pay3 from '@/views/pay3/Pay.vue';
import Pay3Over from '@/views/pay3/PayOver.vue';
import Pay2Right from '@/views/pay2/Rights.vue';
import Pay2Introduce from '@/views/pay2/Introduce.vue';
import Login from '@/views/pay3/Login.vue';
// 测评
import AppraisalIndex from '@/views/appraisal/Index.vue';
import AppraisalCheckSmall from '@/views/appraisal/CheckSmall.vue';
import AppraisalCheckBig from '@/views/appraisal/CheckBig.vue';
import AppraisalResultSmall from '@/views/appraisal/ResultSmall.vue';
import AppraisalResultBig from '@/views/appraisal/ResultBig.vue';
import AppraisalResultList from '@/views/appraisal/ResultList.vue';
import AppraisalVideoList from '@/views/appraisal/VideoList.vue';
import AppraisalVideoDetail from '@/views/appraisal/VideoDetail.vue';

const routers = [
    {
        path: '/complain',
        component: Complain
    },
    {
        path: '/pay',
        component: Pay
    },
    {
        path: '/pay-rights',
        component: PayRights
    },
    {
        path: '/pay-over',
        component: PayOver
    },
    {
        path: '/calc',
        component: Calculate
    },
    {
        path: '/contact',
        component: Pay2
    },
    {
        path: '/rights',
        component: Pay2Right
    },
    {
        path: '/introduce',
        component: Pay2Introduce
    },
    {
        path: '/login',
        component: Login
    },
    {
        path: '/wx/pay',
        component: Pay3
    },
    {
        path: '/wx/pay-over',
        component: Pay3Over
    },
    {
        path: '/appraisal/index',
        component: AppraisalIndex
    },
    {
        path: '/appraisal/check1',
        component: AppraisalCheckSmall
    },
    {
        path: '/appraisal/check2',
        component: AppraisalCheckBig
    },
    {
        path: '/appraisal/result1',
        component: AppraisalResultSmall
    },
    {
        path: '/appraisal/result2',
        component: AppraisalResultBig
    },
    {
        path: '/appraisal/result-list',
        component: AppraisalResultList
    },
    {
        path: '/appraisal/video-list',
        component: AppraisalVideoList
    },
    {
        path: '/appraisal/video-detail',
        component: AppraisalVideoDetail
    }
];

export default new Router({
    mode: "history",
    routes: routers
});
