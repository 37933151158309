const state = {
    question: {}
};

const mutations = {
    'appraisal/setQuestion' (state, obj) {
        state.question = obj;
    }
};

export default {
    state,
    mutations
};
