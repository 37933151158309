<template lang='pug'>
.pay-over-page
    img(src="@/assets/success.png")
    span.value 支付成功
    span.num {{ num }}秒后关闭
</template>

<script>
export default {
    data () {
        return {
            num: 3,
            timer: null
        };
    },
    mounted () {
        this.timer = setInterval(() => {
            this.num += -1;
            if (this.num <= 0 && this.timer) {
                clearInterval(this.timer);
            }
        }, 1000);
        setTimeout(() => {
            WeixinJSBridge.call('closeWindow');
        }, 3000);
    },
    methods: {
    }
};
</script>

<style lang='sass' scoped>
.pay-over-page
    height: 100%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    img 
        width: 2rem
        margin-bottom: .32rem
    .value
        font-size: 18px
        margin-bottom: .32rem
    .num 
        font-size: 18px
</style>
    