import Vue from 'vue';
import App from './App.vue';
import router from './router/router';
import store from './store';
import '@/utils/resize.js'; // 根元素字体大小适配
import './styles/main.sass'; // 主样式

import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

new Vue({
    router,
    store,
    Vant,
    render: h => h(App)
}).$mount('#app');
