<template lang='pug'>
.login-page
    .logo
        img(src="@/assets/logo.png")
    .name 欢迎登录袋鼠照护
    .login-box
        .item
            input(type="number" v-model="phone" placeholder="请输入手机号")
        .item
            input(type="number" v-model="code" placeholder="请输入验证码")
            .code
                .btn(v-if='!isCountDown', @click='getCode') 获取验证码
                .num(v-else) 重新获取 {{ count }}
    .submit(:class="{'disabled': getDisabled()}" @click="login") 登录
</template>

<script>
import axios from 'axios';
export default {
    data () {
        return {
            phone: '',
            code: '',
            count: 0,
            isCountDown: false,
            timer: null,
            loading: false,
            channel: '1005',
            loginLoading: false
        };
    },
    methods: {
        login () {
            if (this.loginLoading) {
                return;
            }
            this.loginLoading = true;
            axios.post('api/vx/User/wechat_login', {
                cellphone: this.phone,
                v_code: this.code
            }, {
                headers: {
                    channel: this.channel
                }
            }).then(resp => {
                let data = resp.data;
                if (data && data.success) {
                    console.log(data);
                    localStorage.setItem('wx_access_token', data.result.access_token);
                    localStorage.setItem('wx_phone', this.phone);
                    this.$nextTick(() => {
                        this.$router.push('/wx/pay');
                    });
                } else {
                    this.$toast(data.errors.message);
                }
            }).finally(() => {
                this.loginLoading = false;
            });
        },
        getCode () {
            if (this.loading) {
                return;
            }
            if (!this.phone) {
                this.$toast('请输入手机号');
                return;
            }
            let flag = this.checkPhoneNum();
            if (!flag) {
                this.$toast('手机号格式不正确');
                return;
            }
            this.loading = true;
            axios.post('api/vx/User/wechat_send_verify_code', {
                cellphone: this.phone,
                channel: this.channel
            }).then((resp) => {
                let data = resp.data;
                if (data && data.success) {
                    this.count = 60;
                    this.isCountDown = true;
                    this.timer = setInterval(() => {
                        if (this.count <= 0) {
                            clearInterval(this.timer);
                            this.isCountDown = false;
                        } else {
                            this.count -= 1;
                        }
                    }, 1000);
                } else {
                    this.$toast(data.errors.message);
                }
            }).finally(() => {
                this.loading = false;
            });
        },
        checkPhoneNum () {
            let myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
            if (!myreg.test(this.phone)) {
                return false;
            } else {
                return true;
            }
        },
        getDisabled () {
            if (!this.phone || !this.code) {
                return true;
            } else {
                return false;
            }
        }
    }
};
</script>

<style lang='sass' scoped>
.login-page
    padding: .6rem
    .logo
        margin-bottom: .24rem
        img 
            width: .88rem
    .name
        font-size: 28px
        font-weight: 500
        margin-bottom: 1rem
    .login-box
        margin-bottom: .6rem
        .item
            height: 1.22rem
            display: flex
            border-bottom: 1px solid rgba(0,0,0,0.1)
            .code
                width: 1.88rem
                line-height: 1.22rem
                color: #05C2CB
                text-align: right
            .num
                color: #999
            input 
                flex: 1
    .submit
        height: 1rem
        background: #05C2CB
        border-radius: 4px
        color: #fff
        text-align: center
        line-height: 1rem
    .disabled 
        opacity: 0.5
</style>
    