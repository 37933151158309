<template lang='pug'>
.appraisal-check-info
    .top
        .tip
            .w1 温馨提示：
            .w2 请输入宝贝最近一次测量的身高体重。
        .form
            .item
                .label 宝贝身高
                .value
                    input(v-model="height", placeholder="请输入")
                    .unit cm
            .item
                .label 宝贝体重
                .value
                    input(v-model="weight", placeholder="请输入")
                    .unit kg
    .bottom
        .submit-btn(@click="submit" :class="{'loading': loading}") 
            van-loading.mr(v-if="loading" type="spinner" size="20")
            span 下一步
</template>

<script>
export default {
    data () {
        return {
            loading: false,
            height: '',
            weight: ''
        };
    },
    mounted () {
        this.height = '';
        this.weight = '';
    },
    methods: {
        submit () {
            if (!this.height || !this.weight) {
                this.$toast('请填写完整');
                return;
            }
            if (this.loading) {
                return;
            }
            this.loading = true;
            this.$emit('confirm', {
                height: this.height,
                weight: this.weight
            });
        }
    }
};
</script>

<style lang='sass' scoped>
.mr
    margin-right: .1rem
.appraisal-check-info
    height: 100%
    width: 100%
    // padding: .4rem
    background: #F4F8FF
    color: #2F3E63
    display: flex 
    flex-direction: column
    .top
        flex: 1
        .tip
            background: #588CF6
            padding: .32rem
            color: #fff
            border-radius: .3rem
            margin-bottom: .6rem
            .w1
                font-size: 16px
                margin-bottom: .1rem
            .w2
                font-size: 13px
        .form
            .item
                margin-bottom: .4rem
                .label
                    margin-bottom: .2rem
                    font-size: 15px
                .value
                    position: relative
                    input
                        line-height: .8rem
                        width: 100%
                        box-shadow: 0px 0px 38px 0px rgba(59,67,73,0.09)
                        padding: 0 1rem 0 .4rem
                        border-radius: .4rem
                    .unit
                        position: absolute
                        width: 1rem
                        text-align: center
                        right: 0
                        top: 0
                        line-height: .8rem
                        color: #9CA5B6
    .bottom       
        padding-bottom: .4rem     
        .submit-btn
            height: .8rem
            border-radius: .5rem
            background: #588CF6
            color: #fff
            font-size: 16px
            font-weight: bold
            text-align: center
            display: flex
            justify-content: center
            align-items: center
        .submit-btn.loading
            opacity: 0.5
</style>