<template lang='pug'>
.pay-over-page
    .wrap(v-if="status == 1")
        .top
            img(src="@/assets/success.png")
            span.value 支付成功
        .bottom
            .btn(@click="closeWindow") 完成
    .wrap(v-if="status == 2")
        .top
            img(src="@/assets/pending.png")
            span.value 订单结果查询中，请稍后
        .bottom
            .btn(@click="closeWindow") 关闭
    .wrap(v-if="status == 3")
        .top
            img(src="@/assets/close.png")
            span.value 支付失败
        .bottom
            .btn(@click="closeWindow") 关闭
</template>

<script>
import moment from 'moment';
import axios from 'axios';
export default {
    data () {
        return {
            status: '',
            timer: null,
            expireTime: 0 // 过期时间
        };
    },
    computed: {
        token () {
            return this.$route.query.token;
        }
    },
    mounted () {
        this.status = '';
        this.timer = null;
        this.getStatus();
    },
    beforeDestroy () {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    },
    methods: {
        getStatus () {
            if (!this.token) {
                return;
            }
            axios.get('/api/order/getLatestOrder', {
                headers: {
                    Authorization: 'bearer ' + this.token,
                    channel: 1000
                }
            }).then(resp => {
                let data = resp.data;
                if (data.result.is_success == 1) {
                    this.status = 1;
                } else {
                    let currentTime = moment().format('X');
                    this.expireTime = Number(data.result.add_time) + 180;
                    console.log(currentTime, this.expireTime);
                    if (currentTime >= this.expireTime) {
                        this.status = 3;
                    } else {
                        this.status = 2;
                        this.checkStatus();
                    }
                }
            });
        },
        checkStatus () {
            this.timer = setInterval(() => {
                let currentTime = Number(moment().format('X'));
                console.log(currentTime, this.expireTime);
                if (currentTime >= this.expireTime) {
                    clearTimeout(this.timer);
                    this.status = 3;
                } else {
                    axios.get('/api/order/getLatestOrder', {
                        headers: {
                            Authorization: 'bearer ' + this.token,
                            channel: 1000
                        }
                    }).then(resp => {
                        let data = resp.data;
                        if (data.result.is_success == 1) {
                            this.status = 1;
                            clearTimeout(this.timer);
                        }
                    });
                }
            }, 5000);
        },
        closeWindow () {
            window.location.href="app://close-webview";
        }
    }
};
</script>

<style lang='sass' scoped>
.pay-over-page
    height: 100%
    .wrap
        height: 100%
        display: flex
        flex-direction: column
        align-items: center
        justify-content: space-between
        padding: 2.4rem 0
        .top
            display: flex
            flex-direction: column
            align-items: center
            img 
                width: 1.2rem
                margin-bottom: .6rem
            .value
                font-size: 20px
                font-weight: 500
                margin-bottom: .32rem
        .bottom 
            .btn
                height: .8rem
                line-height: .8rem
                padding: 0 1.2rem
                border-radius: .1rem
                background: #ebebeb
                font-size: 14px
                font-weight: bold
</style>
    