<template lang='pug'>
.pay2-right-page
    .rights-wrap
        //- .title VIP付费包权益介绍
        .list
            .item(v-for="item in list" :key="item.name")
                .name {{ item.name }}
                .value {{ item.value }}
</template>

<script>
export default {
    data () {
        return {
            list: [
                {
                    name: '7×24小时极速响应',
                    value: '专属医⽣+专属照护团队全天候提供线上咨询'
                },
                {
                    name: '个性化喂养及照护⽅案',
                    value: '专属医⽣+专属照护团队制定个性化⽅案，实时指导，定期线上回访，VIP体验'
                },
                {
                    name: '全程⽣⻓发育监测指导',
                    value: '体格发育监测、神经发育监测，对可能出现发育迟缓的⼊组患⼉，早发现早⼲预早治疗'
                },
                {
                    name: '全程健康监测指导',
                    value: '常⻅疾病护理指导、疫苗接种指导、⽛⻮健康、护眼指导、就医指导'
                },
                {
                    name: '线下就诊VIP体验',
                    value: '单独照护⻔诊、导诊服务（挂号取号、诊前接待、诊后医嘱解释、指导预约检查、检查导诊等）'
                }
            ]
        };
    },
    methods: {
    }
};
</script>

<style lang='sass' scoped>
.pay2-right-page
.rights-wrap
    padding: .64rem .4rem
    .title
        font-size: 16px
        font-weight: bold
        text-align: center
        margin-bottom: .4rem
    .list
        // border-top: 1px solid #eee
        .item
            padding: .44rem 0
            border-bottom: 1px solid #eee
            .name
                text-align: center 
                font-size: 14px 
                font-weight: bold
                margin-bottom: .24rem
            .value
                font-size: 13px
                text-align: center 
</style>
    