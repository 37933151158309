<template lang='pug'>
.appraisal-video-detail
    van-skeleton(
        title,
        :loading="loading"
        :row="10"
    )
        .top-title
            .back(@click="goBack") 返回
            .w1 视频详情
        .video-box
            video.video(
                :src="dataSource.mp4"
                :poster="dataSource.poster"
                controls

            )
            .video-bottom 
                .title {{ dataSource.title }}
                .difficult
                    span 难度
                    van-rate(
                        v-model="dataSource.difficulty" 
                        readonly 
                        color="#62C698" 
                        void-icon="star"
                        void-color="#DBE3ED"
                        size="14"
                    )
        .info-box 
            .info-title 生理目的
            .value {{ dataSource.physiology_aim }}
            .info-title 玩教具
            .value {{ dataSource.toys }}
            .info-title 步骤
            .value.step {{ dataSource.steps }}
            .info-title 强度频率
            .value.no-m {{ dataSource.strength_frequency }}
        .tips-box
            .tips-title 
                .inner 小贴士
            .value {{ dataSource.tips }}
</template>

<script>
import axios from 'axios';
export default {
    data () {
        return {
            channel: 1004,
            loading: false,
            dataSource: {}
        };
    },
    computed: {
        token () {
            return localStorage.getItem('ceping_token');
        },
        id () {
            return this.$route.query.id;
        }
    },
    mounted () {
        if (this.id) {
            this.getData();
        }
    },  
    methods: {
        getData () {
            this.loading = true;
            axios.post('/api/web/Evaluation/videoDetail', {
                id: this.id
            }, {
                headers: {
                    Authorization: 'bearer ' + this.token,
                    channel: this.channel
                }
            }).then((resp) => {
                let data = resp.data;
                if (!data.success) {
                    this.$toast(data.errors.message);
                }
                this.dataSource = data.result;
                console.log(data);
            }).finally(() => {
                this.loading = false;
            });
        },
        goBack () {
            this.$router.back();
        }
    }
};
</script>

<style lang='sass' scoped>
.appraisal-video-detail
    height: 100%
    width: 100%
    overflow: auto
    background: #F4F8FF
    padding: .4rem
    color: #51607D
    .top-title
        margin-bottom: .4rem
        text-align: center
        position: relative
        .back
            position: absolute
            left: 0 
            top: 0
            height: 100%
            font-size: 14px
            color: #5B8EF7
            padding-top: 1px
        .w1
            font-size: 16px
            font-weight: bold
    .video-box
        width: 100%
        background: #FFFFFF
        box-shadow: 0px 0px 38px 0px rgba(59,67,73,0.09)
        border-radius: .3rem
        overflow: hidden
        margin-bottom: .4rem
        video
            width: 100%
        .video-bottom 
            padding: .2rem .4rem .4rem .4rem
            .title
                font-size: 16px
                margin-bottom: .1rem
            .difficult
                font-size: 14px
                color: #9CA5B6
                span
                    margin-right: .1rem
    .info-box 
        width: 100%
        background: #FFFFFF
        box-shadow: 0px 0px 38px 0px rgba(59,67,73,0.09)
        border-radius: .3rem
        margin-bottom: .4rem
        font-size: 14px
        padding: .4rem
        .info-title
            color: #2F3E63
            font-size: 16px
            font-weight: 600
            margin-bottom: .2rem
            padding-left: .2rem
            position: relative
        .info-title::after
            content: ''
            position: absolute
            left: 0
            top: 0
            height: 100%
            width: .1rem
            background: #588CF6
            border-radius: .06rem
        .value
            margin-bottom: .2rem
            line-height: .44rem
        .step
            white-space: pre-wrap
        .no-m
            margin: 0
    .tips-box
        idth: 100%
        background: #FFFFFF
        box-shadow: 0px 0px 38px 0px rgba(59,67,73,0.09)
        border-radius: .3rem
        .tips-title
            height: .6rem
            display: flex
            justify-content: center
            .inner
                background: #588CF6
                border-bottom-left-radius: .6rem
                border-bottom-right-radius: .6rem
                width: 3rem
                height: .6rem
                text-align: center
                line-height: .6rem
                font-size: 16px 
                font-weight: bold
                color: #fff
        .value 
            padding: .4rem
            font-size: 14px
</style>